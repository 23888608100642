import React, { useState, useEffect, useRef } from 'react';
import { View, TextInput, StyleSheet, Text, TouchableOpacity, ScrollView } from 'react-native';
import { useScrollToTop } from '@react-navigation/native';

export default function SendNotification({ appInfo, title, setTitle, body, setBody, pushData, setPushData, bigPictureURL, setBigPictureURL, sendNotification }) {
    const [notReceipts, setNotReceipts] = useState([]);
    const [showDataInput, setShowDataInput] = useState(false);
    const [showSee, setShowSee] = useState(false);
    const [showAPI, setShowAPI] = useState(false);

    const date = new Date();
    let hour = date.getHours();
    let amPM = "";

    if (hour === 12) {
        amPM = "PM"
    } else if (hour > 12) {
        amPM = "PM"
        hour = hour - 12;
    } else {
        amPM = "AM"
    }

    const scrollRef = useRef();
    // useScrollToTop(ref);

    useEffect(() => {
        // console.log(appInfo.notifications_sent);
        if (appInfo.notifications_sent) {
            setNotReceipts(appInfo.notifications_sent.slice(0, 100));
        }
        window.scroll({ top: 0, left: 0, behavior: 'instant' });
    }, [appInfo])

    return (
        <ScrollView style={styles.body} ref={scrollRef}>
            <Text style={styles.HeadingTextTop}>Send a Push Notification Now:</Text>
            <View style={styles.inputCont}>
                <Text style={styles.text}>Notification Title:</Text>
                <TextInput
                    style={styles.inputLarge}
                    onChangeText={(text) => setTitle(text)}
                    placeholder={"Type Notification Title here..."}
                    placeholderTextColor={"#6e6d6c"}
                    value={title}
                />
            </View>
            <View style={styles.inputCont}>
                <Text style={styles.text}>Notification Message:</Text>
                <TextInput
                    style={styles.inputLarge}
                    onChangeText={(text) => setBody(text)}
                    placeholder={"Type Notification Message here..."}
                    placeholderTextColor={"#6e6d6c"}
                    value={body}
                    multiline={true}
                />
            </View>

            <TouchableOpacity style={styles.advancedText} onPress={() => setShowDataInput(!showDataInput)}>
                <Text>Advanced Settings (optional)</Text> {showDataInput ? <Text>{"   "}&#8593;</Text> : <Text>{"   "}&#8595;</Text>}
            </TouchableOpacity>
            {showDataInput
                ? <View style={styles.inputCont}>
                    <Text style={styles.dataObjText}>Big Picture URL:</Text>
                    <Text style={styles.seeText}>(Big Pictures only work with Android in production mode.)</Text>
                    <TextInput
                        style={styles.inputLarge}
                        onChangeText={(text) => setBigPictureURL(text)}
                        placeholder={'Type the URL to the big picture you would like to appear in your Android push notification. (Big pictures do not work with iOS.)'}
                        placeholderTextColor={"#6e6d6c"}
                        value={bigPictureURL}
                        multiline={true}
                    />

                    <Text style={styles.dataObjText}>Data Object:</Text>
                    <Text style={styles.seeText}>(Push data objects will not work in Android production if you send a Big Picture with your push notification. Leave the Big Picture URL input blank if you need to send a push data object with your push notification.)</Text>
                    <TextInput
                        style={styles.inputLarge}
                        onChangeText={(text) => setPushData(text)}
                        placeholder={'Type data object here in this format: { "propertyName":"propertyValue" }'}
                        placeholderTextColor={"#6e6d6c"}
                        value={pushData}
                        multiline={true}
                    />

                    <TouchableOpacity style={styles.seeText} onPress={() => setShowSee(!showSee)}>
                        <Text>See how Data Objects work</Text>
                        {showSee ? <Text>{"   "}&#8593;</Text> : <Text>{"   "}&#8595;</Text>}
                    </TouchableOpacity>
                    {showSee
                        ? <View style={styles.blueBackCont}>
                            <Text style={styles.boldText}>Follow these steps to see push data objects in your app:</Text>

                            <Text style={styles.boldText}>Step 1:</Text>
                            <Text style={styles.blackBacktext}>
                                <Text style={styles.mbSmall}>npm uninstall native-notify</Text>
                                <Text style={styles.mbSmall}>npm install native-notify</Text>
                                <Text>npx expo install expo-device expo-notifications expo-constants</Text>
                            </Text>

                            <Text style={styles.boldText}>Step 2:</Text>
                            <Text style={styles.plainText}>Make sure these imports are included at the top of your App.js screen:</Text>
                            <Text style={styles.blackBacktext}>
                                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>React, {"{ useState, useEffect }"} </Text><Text style={styles.purpleText}>from</Text> 'react';</Text>
                                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>{"{ getPushDataObject }"} </Text><Text style={styles.purpleText}>from</Text> 'native-notify';</Text>
                            </Text>

                            <Text style={styles.boldText}>Step 3:</Text>
                            <Text style={styles.plainText}>Make sure you are using a Hook function as your App function. Here is an example:</Text>
                            <Text style={styles.blackBacktext}>
                                <Text><Text style={styles.purpleText}>export default</Text> <Text style={styles.medBlueText}>function</Text> <Text style={styles.yellowText}>App</Text>() {"{"}</Text>
                                <Text>     ...</Text>
                                <Text>{"}"}</Text>
                            </Text>
                            <Text style={styles.plainText}>
                                This link explains Hooks in detail: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://reactjs.org/docs/hooks-intro.html')}>https://reactjs.org/docs/hooks-intro.html</TouchableOpacity>
                            </Text>

                            <Text style={styles.boldText}>Step 4:</Text>
                            <Text style={styles.plainText}>Inside of your App function, paste this code:</Text>
                            <Text style={styles.blackBacktext}>
                                <Text><Text style={styles.blueText}>let</Text> <Text style={styles.lightBlueText}>pushDataObject</Text> = <Text style={styles.yellowText}>getPushDataObject</Text>();</Text>
                            </Text>

                            <Text style={styles.boldText}>Step 5:</Text>
                            <Text style={styles.plainText}>Inside of your App function, create a 'useEffect' function if you have not already. You can see the value of your Push Data Object in the useEffect function whenever the user taps on your push notification like this:</Text>
                            <Text style={styles.blackBacktext}>
                                <Text><Text style={styles.yellowText}>useEffect</Text>(() => {"{"}</Text>
                                <Text>     console.log(<Text style={styles.lightBlueText}>pushDataObject</Text>);</Text>
                                <Text>{"}, [pushDataObject]);"}</Text>
                            </Text>
                            <Text style={styles.plainText}>
                                This link explains how 'useEffect' works in detail: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://reactjs.org/docs/hooks-effect.html')}>https://reactjs.org/docs/hooks-effect.html</TouchableOpacity>
                            </Text>

                            <View style={styles.dottedLine}></View>

                            <Text style={styles.boldTextTop}>Here is an example of an App.js component with Native Notify Push Data Object code included:</Text>
                            <Text style={styles.blackBacktext}>
                                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>React, {"{ useState, useEffect }"} </Text><Text style={styles.purpleText}>from</Text> 'react';</Text>
                                <Text style={styles.mb}><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>{"registerNNPushToken, { getPushDataObject }"}</Text> <Text style={styles.purpleText}>from</Text> 'native-notify';</Text>

                                <Text><Text style={styles.purpleText}>export default</Text> <Text style={styles.medBlueText}>function</Text> <Text style={styles.yellowText}>App</Text>() {"{"}</Text>
                                <Text style={styles.mb}>     <Text style={styles.yellowText}>registerNNPushToken</Text>({appInfo.app_id}, '{appInfo.app_token}');</Text>

                                <Text style={styles.mb}>     <Text style={styles.blueText}>let</Text> <Text style={styles.lightBlueText}>pushDataObject</Text> = <Text style={styles.yellowText}>getPushDataObject</Text>();</Text>

                                <Text>     <Text style={styles.yellowText}>useEffect</Text>(() => {"{"}</Text>
                                <Text>          console.log(<Text style={styles.lightBlueText}>pushDataObject</Text>);</Text>
                                <Text style={styles.mb}>     {"}, [pushDataObject]);"}</Text>

                                <Text>     <Text style={styles.purpleText}>return</Text> (</Text>
                                <Text style={styles.tab2}>...</Text>
                                <Text>     )</Text>
                                <Text>{"}"}</Text>
                            </Text>

                        </View>
                        : null
                    }
                </View>
                : null
            }

            <TouchableOpacity style={styles.sendButton} onPress={() => sendNotification()}>
                <Text style={styles.buttonText}>Send Push Notification</Text>
            </TouchableOpacity>

            {appInfo.app_sub_count > 0
                ? <Text style={styles.HeadingText}><Text style={styles.HeadingTextBold}>Push Notification Subscriber Count: </Text>
                    {appInfo.indie_push_subs && appInfo.indie_push_subs.length > 0
                        ? appInfo.app_sub_count + appInfo.indie_push_subs.length
                        : appInfo.app_sub_count
                    }
                </Text>
                : null
            }

            <Text style={styles.HeadingText}>Did you know you can send emojis in your push notifications? A collection of emojis can be found here: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://emojiterra.com')}><Text>https://emojiterra.com</Text></TouchableOpacity>.</Text>

            <TouchableOpacity style={styles.showAPIButton} onPress={() => setShowAPI(!showAPI)}>
                <Text style={styles.buttonText}>Send API (optional): {showAPI ? <Text>{" "}&#8593;</Text> : <Text>{" "}&#8595;</Text>}</Text>
            </TouchableOpacity>
            {showAPI
                ? <View>
                    <View style={styles.dottedLine}></View>
                    <Text style={styles.mb}></Text>
                    <Text style={styles.mb}>You can also send push notifications using our API if you would like. Here are the steps:</Text>
                    <Text style={styles.boldText}>Step 1:</Text>
                    <Text style={styles.plainText}>Post to this URL:</Text>
                    <Text style={styles.blackBacktext}>
                        <Text>https://app.nativenotify.com/api/notification</Text>
                    </Text>
                    <Text style={styles.boldText}>Step 2:</Text>
                    <Text style={styles.plainText}>Use this post body (replacing the 'dateSent' with your current date):</Text>
                    <Text style={styles.blackBacktext}>
                        <Text> {`{`}</Text>
                        <Text>      appId: {appInfo.app_id},</Text>
                        <Text>      appToken: "{appInfo.app_token}",</Text>
                        <Text>      title: "Push title here as a string",</Text>
                        <Text>      body: "Push message here as a string",</Text>
                        <Text>      dateSent: "{(date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear() + " " + hour + ":" + ('0' + date.getMinutes()).slice(-2) + amPM}",</Text>
                        <Text>      pushData: {'{ yourProperty: "yourPropertyValue" }'},</Text>
                        <Text>      bigPictureURL: {'Big picture URL as a string'}</Text>
                        <Text> {" }"}</Text>
                    </Text>

                    <Text style={styles.mb}></Text>
                    <Text style={styles.plainText}>"pushData" and "bigPictureURL" keys are optional. You can leave them out of the post request if you do not wish to send data or a big picture with your push notification.</Text>
                    <Text style={styles.boldText}>If you are using a non-javascript based server language like Python, make sure to specify that this is an 'application/json' post type in your post header.</Text>
                    <Text style={styles.mb}></Text>
                    <View style={styles.dottedLine}></View>
                    <Text style={styles.mb}></Text>
                </View>
                : null
            }

            <Text style={styles.text}>Notification Receipts:</Text>
            {notReceipts.map((e, i) => {
                let obj = JSON.parse(e);

                return (
                    <View style={styles.recCont} key={i}>
                        <Text style={styles.recText}><Text style={styles.recTextBold}>Date:</Text>     {obj.dateSent}</Text>
                        <Text style={styles.recText}><Text style={styles.recTextBold}>Users sent notification:</Text>     {obj.numberOfSubsSentNotification}</Text>
                        {/* <Text style={styles.recText}><Text style={styles.recTextBold}>Users who successfully received notification:</Text>     {obj.numberOfSubsReceivedNotification}</Text> */}
                        <Text style={styles.recText}><Text style={styles.recTextBold}>Title of notification:</Text>     {obj.title}</Text>
                        <Text style={styles.recText}><Text style={styles.recTextBold}>Message of notification:</Text>     {obj.body}</Text>
                    </View>
                )
            })}
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    body: {
        width: '85%',
        paddingTop: 30,
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingBottom: 100
    },
    HeadingTextTop: {
        fontFamily: "Arial",
        fontSize: 20,
        fontWeight: 700,
        marginBottom: 30
    },
    HeadingText: {
        fontFamily: "Arial",
        fontSize: 16,
        marginBottom: 30
    },
    HeadingTextBold: {
        fontFamily: "Arial",
        fontSize: 16,
        fontWeight: 700,
        marginBottom: 30
    },
    plainText: {
        fontFamily: "Arial",
        fontSize: 16,
        marginBottom: 5
    },
    buttonText: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 16,
        color: '#fff',
    },
    guideButton: {
        width: 150,
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
        marginBottom: 70,
    },
    sendButton: {
        width: 220,
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
        marginTop: 30,
        marginBottom: 70,
    },
    showAPIButton: {
        width: 220,
        backgroundColor: 'black',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
        marginTop: 0,
        marginBottom: 30,
    },
    inputCont: {
        marginBottom: 20,
        width: '100%',
    },
    inputLarge: {
        width: '100%',
        padding: 12.5,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 15
    },

    recCont: {
        width: '100%',
        backgroundColor: '#fff',
        marginTop: 10,
        marginBottom: 10,
        padding: 20,
        paddingBottom: 15,
        borderRadius: 6,
        flexWrap: 'wrap',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
    },
    recText: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 14,
        marginBottom: 5,
    },
    recTextBold: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 5,
    },

    text: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginBottom: 5
    },
    blueText: {
        color: "rgb(54, 117, 212)"
    },
    advancedText: {
        flexDirection: 'row',
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 14,
        marginTop: -10,
        marginBottom: 10
    },
    dataObjText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginTop: 15,
        marginBottom: 5
    },
    seeText: {
        flexDirection: 'row',
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 14,
        marginBottom: 10,
    },
    blackBacktext: {
        backgroundColor: "#1b1f23",
        color: "#fff",
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        padding: 20,
        borderRadius: 6,
        marginBottom: 5,
        display: "flex",
        flexDirection: "column"
    },
    tab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50
    },
    greentab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50,
        color: 'rgb(81, 167, 81)'
    },
    yellowText: {
        color: "rgb(222, 222, 160)"
    },
    mb: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 20
    },
    lightBlueText: {
        color: "rgb(144, 180, 218)"
    },
    boldText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginTop: 20,
        marginBottom: 5
    },
    boldTextTop: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        // marginTop: 20,
        marginBottom: 5
    },
    purpleText: {
        color: "rgb(187, 86, 187)"
    },
    blueBackCont: {
        backgroundColor: '#3675d420',
        padding: 15,
        borderRadius: 6
    },
    dottedLine: {
        height: 1,
        width: "100%",
        borderTopWidth: 1,
        borderColor: "#000",
        borderStyle: "dotted",
        marginTop: 30,
        marginBottom: 30,
    }
});
import React, { useState, useEffect, useRef } from 'react';
import { View, TextInput, StyleSheet, Text, TouchableOpacity, Platform } from 'react-native';
import axios from 'axios';

export default function NotificationInbox({ host, appInfo, InAppNavigation }) {
    const { setScreenName } = InAppNavigation;

    const [showDataInput, setShowDataInput] = useState(false);
    const [showSee, setShowSee] = useState(false);

    const [selectTopicGroups, setSelectTopicGroups] = useState([]);
    const [topicGroupNameForPush, setTopicGroupNameForPush] = useState('');
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [pushData, setPushData] = useState('');
    const [bigPictureURL, setBigPictureURL] = useState('');

    const [topicGroupName, setTopicGroupName] = useState('');
    const [showTopicAPIs, setShowTopicAPIs] = useState(false);
    const [topicGroups, setTopicGroups] = useState([]);

    useEffect(() => {
        // console.log(appInfo.app_id, appInfo.app_token);
        axios
            .get(`${host}/api/all/app/topic/groups/${appInfo.app_id}/${appInfo.app_token}`)
            .then(res => {
                // console.log(res.data);
                setTopicGroups(res.data);
                for (const topic of res.data) {
                    setSelectTopicGroups((prevTopics) => [...prevTopics, topic.master_indie_id]);
                }
            })
            .catch(err => console.log(err))
    }, [])

    const createTopicGroup = () => {
        // console.log(topicGroupName.length, topicGroupName);

        if (topicGroupName.length > 0) {
            axios
                .post(`${host}/api/post/follow/master`, {
                    masterSubID: topicGroupName,
                    appId: appInfo.app_id,
                    appToken: appInfo.app_token,
                    isTopicGroup: true
                })
                .then(() => {
                    axios
                        .get(`${host}/api/all/app/topic/groups/${appInfo.app_id}/${appInfo.app_token}`)
                        .then(res => {
                            setTopicGroups(res.data);
                            setSelectTopicGroups((prevTopics) => [...prevTopics, topicGroupName]);
                            setTopicGroupName('');
                        })
                        .catch(err => console.log(err))
                })
                .catch(err => console.log(err))
        }
    }

    const sendTopicNotification = () => {
        if (topicGroupNameForPush && title && body) {
            axios
                .post(`${host}/api/follow/notification`, {
                    masterSubID: topicGroupNameForPush,
                    appId: appInfo.app_id,
                    appToken: appInfo.app_token,
                    title: title,
                    message: body,
                    pushData: pushData,
                    bigPictureURL: bigPictureURL
                })
                .then(res => {
                    // console.log(res.data);
                    if (res.data === "The ID does not exist or the ID has no followers/subscribers.") {
                        alert("The Topic Group Name does not exist or the Topic Group Name has no followers/subscribers.")
                    }
                    setTopicGroupNameForPush('');
                    setTitle('');
                    setBody('');
                    setPushData('');
                    setBigPictureURL('');
                })
                .catch(err => console.log(err))
        } else {
            alert("Fill in the Topic Group Name, the Title, and the Message inputs before clicking the send button.");
        }

    }

    return (
        <View style={styles.body}>
            {/* "send topic group notification" */}
            {selectTopicGroups.length > 0
                ? <View>
                    <Text style={styles.subHeadingText}>Send a Topic Group Push Notification:</Text>
                    <View style={styles.container}>
                        <View style={styles.mb}></View>
                        <View style={styles.topicSelCont}>
                            <View style={styles.topicSelText}>Select a Topic Group:</View>
                            {selectTopicGroups.map((e, i) => {
                                // console.log('e: ', e);
                                return (
                                    <View key={i}>
                                        <TouchableOpacity
                                            style={styles.buttonTopic}
                                            onPress={() => setTopicGroupNameForPush(e)}
                                            key={i}
                                        >
                                            <Text style={styles.buttonText}>
                                                {e}
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                )
                            })}
                        </View>
                        <TextInput
                            style={styles.inputLarge}
                            onChangeText={(text) => setTopicGroupNameForPush(text)}
                            placeholder={"Topic Group Name"}
                            placeholderTextColor={"#6e6d6c"}
                            value={topicGroupNameForPush}
                        />
                        <TextInput
                            style={styles.inputLarge}
                            onChangeText={(text) => setTitle(text)}
                            placeholder={"Push Notification Title"}
                            placeholderTextColor={"#6e6d6c"}
                            value={title}
                        />
                        <TextInput
                            style={styles.inputLarge}
                            onChangeText={(text) => setBody(text)}
                            placeholder={"Push Notification Message"}
                            placeholderTextColor={"#6e6d6c"}
                            value={body}
                        />
                        <TouchableOpacity style={styles.advancedText} onPress={() => setShowDataInput(!showDataInput)}>
                            <Text>Advanced Settings (optional)</Text> {showDataInput ? <Text>{"   "}&#8593;</Text> : <Text>{"   "}&#8595;</Text>}
                        </TouchableOpacity>
                        {showDataInput
                            ? <View style={styles.inputCont}>
                                <Text style={styles.dataObjText}>Big Picture URL:</Text>
                                <Text style={styles.seeText}>(Big Pictures only work with Android in production mode.)</Text>
                                <TextInput
                                    style={styles.inputLarge}
                                    onChangeText={(text) => setBigPictureURL(text)}
                                    placeholder={'Type the URL to the big picture you would like to appear in your Android push notification. (Big pictures do not work with iOS.)'}
                                    placeholderTextColor={"#6e6d6c"}
                                    value={bigPictureURL}
                                    multiline={true}
                                />

                                <Text style={styles.dataObjText}>Data Object:</Text>
                                <Text style={styles.seeText}>(Push data objects will not work in Android production if you send a Big Picture with your push notification. Leave the Big Picture URL input blank if you need to send a push data object with your push notification.)</Text>
                                <TextInput
                                    style={styles.inputLarge}
                                    onChangeText={(text) => setPushData(text)}
                                    placeholder={'Type data object here in this format: { "propertyName":"propertyValue" }'}
                                    placeholderTextColor={"#6e6d6c"}
                                    value={pushData}
                                    multiline={true}
                                />

                                <TouchableOpacity style={styles.seeText} onPress={() => setShowSee(!showSee)}>
                                    <Text>See how Data Objects work</Text>
                                    {showSee ? <Text>{"   "}&#8593;</Text> : <Text>{"   "}&#8595;</Text>}
                                </TouchableOpacity>
                                {showSee
                                    ? <View style={styles.blueBackCont}>
                                        <Text style={styles.boldTextTop}>Follow these steps to see push data objects in your app:</Text>

                                        <Text style={styles.boldText}>Step 1:</Text>
                                        <Text style={styles.blackBackText}>
                                            <Text style={styles.mbSmall}>npm uninstall native-notify</Text>
                                            <Text style={styles.mbSmall}>npm install native-notify</Text>
                                            <Text>npx expo install expo-device expo-notifications expo-constants</Text>
                                        </Text>

                                        <Text style={styles.boldText}>Step 2:</Text>
                                        <Text style={styles.plainText}>Make sure these imports are included at the top of your App.js screen:</Text>
                                        <Text style={styles.blackBackText}>
                                            <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>React, {"{ useState, useEffect }"} </Text><Text style={styles.purpleText}>from</Text> 'react';</Text>
                                            <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>{"{ getPushDataObject }"} </Text><Text style={styles.purpleText}>from</Text> 'native-notify';</Text>
                                        </Text>

                                        <Text style={styles.boldText}>Step 3:</Text>
                                        <Text style={styles.plainText}>Make sure you are using a Hook function as your App function. Here is an example:</Text>
                                        <Text style={styles.blackBackText}>
                                            <Text><Text style={styles.purpleText}>export default</Text> <Text style={styles.medBlueText}>function</Text> <Text style={styles.yellowText}>App</Text>() {"{"}</Text>
                                            <Text>     ...</Text>
                                            <Text>{"}"}</Text>
                                        </Text>
                                        <Text style={styles.plainText}>
                                            This link explains Hooks in detail: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://reactjs.org/docs/hooks-intro.html')}>https://reactjs.org/docs/hooks-intro.html</TouchableOpacity>
                                        </Text>

                                        <Text style={styles.boldText}>Step 4:</Text>
                                        <Text style={styles.plainText}>Inside of your App function, paste this code:</Text>
                                        <Text style={styles.blackBackText}>
                                            <Text><Text style={styles.blueText}>let</Text> <Text style={styles.lightBlueText}>pushDataObject</Text> = <Text style={styles.yellowText}>getPushDataObject</Text>();</Text>
                                        </Text>

                                        <Text style={styles.boldText}>Step 5:</Text>
                                        <Text style={styles.plainText}>Inside of your App function, create a 'useEffect' function if you have not already. You can see the value of your Push Data Object in the useEffect function whenever the user taps on your push notification like this:</Text>
                                        <Text style={styles.blackBackText}>
                                            <Text><Text style={styles.yellowText}>useEffect</Text>(() => {"{"}</Text>
                                            <Text>     console.log(<Text style={styles.lightBlueText}>pushDataObject</Text>);</Text>
                                            <Text>{"});"}</Text>
                                        </Text>
                                        <Text style={styles.plainText}>
                                            This link explains how 'useEffect' works in detail: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://reactjs.org/docs/hooks-effect.html')}>https://reactjs.org/docs/hooks-effect.html</TouchableOpacity>
                                        </Text>

                                        <View style={styles.dottedLine}></View>

                                        <Text style={styles.boldTextTop}>Here is an example of an App.js component with Native Notify Push Data Object code included:</Text>
                                        <Text style={styles.blackBackText}>
                                            <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>React, {"{ useState, useEffect }"} </Text><Text style={styles.purpleText}>from</Text> 'react';</Text>
                                            <Text style={styles.mb}><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>{"registerNNPushToken, { getPushDataObject }"}</Text> <Text style={styles.purpleText}>from</Text> 'native-notify';</Text>

                                            <Text><Text style={styles.purpleText}>export default</Text> <Text style={styles.medBlueText}>function</Text> <Text style={styles.yellowText}>App</Text>() {"{"}</Text>
                                            <Text style={styles.mb}>     <Text style={styles.yellowText}>registerNNPushToken</Text>({appInfo.app_id}, '{appInfo.app_token}');</Text>

                                            <Text style={styles.mb}>     <Text style={styles.blueText}>let</Text> <Text style={styles.lightBlueText}>pushDataObject</Text> = <Text style={styles.yellowText}>getPushDataObject</Text>();</Text>

                                            <Text>     <Text style={styles.yellowText}>useEffect</Text>(() => {"{"}</Text>
                                            <Text>          console.log(<Text style={styles.lightBlueText}>pushDataObject</Text>);</Text>
                                            <Text style={styles.mb}>     {"});"}</Text>

                                            <Text>     <Text style={styles.purpleText}>return</Text> (</Text>
                                            <Text style={styles.tab2}>...</Text>
                                            <Text>     )</Text>
                                            <Text>{"}"}</Text>
                                        </Text>

                                    </View>
                                    : null
                                }
                            </View>
                            : null
                        }
                        <View style={styles.mb}></View>
                        <View style={styles.mb}></View>

                        <TouchableOpacity style={styles.button} onPress={() => sendTopicNotification()}>
                            <Text style={styles.buttonText}>Send Push Notification</Text>
                        </TouchableOpacity>
                    </View>

                </View>
                : null
            }

            <View style={styles.mb}></View>
            <View style={styles.mb}></View>
            <View style={styles.mb}></View>

            <Text style={styles.headingText}>Topic Groups Setup Guide</Text>
            <Text style={styles.plainText}>Create Topic Groups. Subscribe users to a topic. Send notifications ONLY to the users subscribed to that topic.</Text>

            <Text style={styles.boldText}>
                Indie Push setup is required for Topic Groups to work. Go to Indie Push Setup Guide to set up Indie Push Notifications if you have not already: <TouchableOpacity style={styles.blueText} onPress={() => setScreenName('indieInstallDocs')}><Text>Indie Push Setup Guide</Text></TouchableOpacity>
            </Text>

            <View style={styles.mb}></View>

            {Platform.OS === "web"
                ? <View style={styles.youTubeVideo}>
                    <Text style={styles.h2}>This video walks you through the setup guide below:</Text>
                    <iframe width="400" height="220" style={{ maxWidth: '100%' }} src="https://www.youtube.com/embed/oQY_1kFhC08?rel=0" title="Native Notify Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen="allowfullscreen"></iframe>

                </View>
                : null
            }

            {/* create topic group */}
            <Text style={styles.subHeadingText}>Create a Topic Group:</Text>
            <View style={styles.container}>
                <View style={styles.mb}></View>
                <TextInput
                    style={styles.inputLarge}
                    onChangeText={(text) => setTopicGroupName(text)}
                    placeholder={"Type Topic Group name here..."}
                    placeholderTextColor={"#6e6d6c"}
                    value={topicGroupName}
                />
                <TouchableOpacity style={styles.buttonCreate} onPress={() => createTopicGroup()}>
                    <Text style={styles.buttonText}>Create Topic Group</Text>
                </TouchableOpacity>
            </View>

            {/* topic group api */}
            <Text style={styles.subHeadingText}>Topic Group APIs:</Text>
            <View style={styles.container}>
                <TouchableOpacity style={styles.button} onPress={() => setShowTopicAPIs(!showTopicAPIs)}>
                    <Text style={styles.buttonText}>{showTopicAPIs ? 'Hide Topic Group APIs' : 'Show Topic Group APIs'}</Text>
                </TouchableOpacity>

                {showTopicAPIs
                    ? <View>
                        <View style={styles.mb}></View>
                        <View style={styles.mb}></View>

                        <Text style={styles.containerTextBold}>Get names of all Topic Groups:</Text>
                        <Text style={styles.containerText}>API GET URL: </Text>
                        <Text style={styles.blackBackTextFlexRow}>https://app.nativenotify.com/api/all/app/topic/group/names/{appInfo.app_id}/{appInfo.app_token}</Text>

                        <View style={styles.mb}></View>
                        <View style={styles.mb}></View>

                        <Text style={styles.apiSubHeadingText}>Subscribe a User to a Topic Group:</Text>
                        <Text style={styles.containerText}>API POST URL: </Text>
                        <Text style={styles.blackBackText}>https://app.nativenotify.com/api/post/follower</Text>
                        <Text style={styles.containerText}>API POST Body: </Text>
                        <Text style={styles.blackBackText}>
                            <Text>{'{'}</Text>
                            <Text>     "masterSubID": <Text style={styles.blueText}>"your Topic Group name"</Text>,</Text>
                            <Text>     "followerSubID": <Text style={styles.blueText}>"the Indie Sub ID you want to subscribe to this Topic Group"</Text>,</Text>
                            <Text>     "appId": {appInfo.app_id},</Text>
                            <Text>     "appToken": "{appInfo.app_token}"</Text>
                            <Text>{'}'}</Text>
                        </Text>

                        <View style={styles.mb}></View>
                        <View style={styles.mb}></View>

                        <Text style={styles.containerTextBold}>Unsubscribe a User from a Topic Group:</Text>
                        <Text style={styles.containerText}>API PUT URL: </Text>
                        <Text style={styles.blackBackText}>https://app.nativenotify.com/api/unfollow/master</Text>
                        <Text style={styles.containerText}>API PUT Body: </Text>
                        <Text style={styles.blackBackText}>
                            <Text>{'{'}</Text>
                            <Text>     "masterSubID": <Text style={styles.blueText}>"your Topic Group name"</Text>,</Text>
                            <Text>     "followerSubID": <Text style={styles.blueText}>"the Indie Sub ID you want to unsubscribe from this Topic Group"</Text>,</Text>
                            <Text>     "appId": {appInfo.app_id},</Text>
                            <Text>     "appToken": "{appInfo.app_token}"</Text>
                            <Text>{'}'}</Text>
                        </Text>

                        <View style={styles.mb}></View>
                        <View style={styles.mb}></View>

                        <Text style={styles.containerTextBold}>Show what Indie Sub Ids are subscribed to a Topic Group:</Text>
                        <Text style={styles.containerText}>API GET URL: </Text>
                        <Text style={styles.blackBackTextFlexRow}>https://app.nativenotify.com/api/topic/group/<Text style={styles.blueText}>your-topic-group-name</Text>/{appInfo.app_id}/{appInfo.app_token}</Text>

                        <View style={styles.mb}></View>
                        <View style={styles.mb}></View>

                        <Text style={styles.containerTextBold}>Delete a Topic Group:</Text>
                        <Text style={styles.containerText}>API DELETE URL: </Text>
                        <Text style={styles.blackBackTextFlexRow}>
                            https://app.nativenotify.com/api/follow/master/
                            <Text>{appInfo.app_id}</Text>/
                            <Text>{appInfo.app_token}</Text>/
                            <Text style={styles.blueText}>topic-group-name</Text>
                        </Text>
                    </View>
                    : null
                }
            </View>




            {/* put the send function here. a single send function where they have to physically type a topic group name */}

            <Text style={styles.subHeadingText}>Created Topic Groups:</Text>
            <View style={styles.flexCont}>

                {topicGroups.map((e, i) => {
                    return (
                        <View style={styles.containerBottom} key={i}>
                            <Text style={styles.containerText}>
                                <Text style={styles.containerTextBold}>Topic Group Name: </Text>
                                {e.master_indie_id}
                            </Text>
                            <Text style={styles.containerText}>
                                <Text style={styles.containerTextBold}>Topic Group Subscriber Count: </Text>
                                {e.follower_count}
                            </Text>
                            {/* put sendFollowNotification inputs and send button here */}
                        </View>
                    )
                })}
            </View>


        </View>
    )
}

const styles = StyleSheet.create({
    body: {
        width: '85%',
        paddingTop: 30,
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingBottom: 200
    },
    headingText: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        // marginTop: 70,
        marginBottom: 10
    },
    h2: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 20,
        marginBottom: 10
    },
    subHeadingText: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 20,
        marginTop: 30,
        marginBottom: 10
    },
    plainText: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 5
    },
    flexCont: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        margin: -10,
        marginTop: 0
    },
    containerBottom: {
        backgroundColor: '#fff',
        margin: 10,
        marginTop: 10,
        marginBottom: 10,
        padding: 20,
        borderRadius: 6,
        flexWrap: 'wrap',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5
    },
    container: {
        width: '100%',
        backgroundColor: '#fff',
        marginTop: 10,
        marginBottom: 10,
        padding: 20,
        borderRadius: 6,
        flexWrap: 'wrap',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5
    },
    containerText: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 14,
        marginBottom: 5,
        wordBreak: 'break-word'
    },
    containerTextBold: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 5,
    },
    apiHeadingText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 16,
        marginBottom: 30,
        textAlign: 'center'
    },
    apiSubHeadingText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 10
    },
    button: {
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
        paddingTop: 10,
        paddingBottom: 10,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
    },
    buttonCreate: {
        backgroundColor: '#e44258',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
        paddingTop: 10,
        paddingBottom: 10,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
    },
    topicSelCont: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginBottom: 20,
    },
    topicSelText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 14,
    },
    buttonTopic: {
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        paddingTop: 5,
        paddingBottom: 5,
        margin: 10,
        marginTop: 0,
        marginBottom: 0,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
    },
    buttonText: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 14,
        color: '#fff',
    },
    mb: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 10
    },
    blueText: {
        color: "rgb(54, 117, 212)"
    },
    boldText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginTop: 20,
        marginBottom: 5
    },
    inputLarge: {
        width: '100%',
        padding: 12.5,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 15
    },
    blackBackText: {
        maxWidth: '100%',
        backgroundColor: "#1b1f23",
        color: "#fff",
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        padding: 20,
        borderRadius: 6,
        marginBottom: 5,
        display: "flex",
        flexDirection: "column"
    },
    blackBackTextFlexRow: {
        maxWidth: '100%',
        backgroundColor: "#1b1f23",
        color: "#fff",
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        padding: 20,
        borderRadius: 6,
        marginBottom: 5,
    },
    advancedText: {
        flexDirection: 'row',
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 14,
        marginTop: 10,
        marginBottom: 10
    },
    dataObjText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginTop: 15,
        marginBottom: 5
    },
    seeText: {
        flexDirection: 'row',
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 14,
        marginBottom: 10,
    },
    tab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50
    },
    greentab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50,
        color: 'rgb(81, 167, 81)'
    },
    yellowText: {
        color: "rgb(222, 222, 160)"
    },
    blueBackCont: {
        backgroundColor: '#3675d420',
        padding: 15,
        borderRadius: 6,
        marginBottom: 20
    },
    boldTextTop: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        // marginTop: 20,
        marginBottom: 5
    },
    dottedLine: {
        height: 1,
        width: "100%",
        borderTopWidth: 1,
        borderColor: "#000",
        borderStyle: "dotted",
        marginTop: 30,
        marginBottom: 30,
    },
    purpleText: {
        color: "rgb(187, 86, 187)"
    },
    lightBlueText: {
        color: "rgb(144, 180, 218)"
    }
})
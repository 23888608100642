import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Text, Image, TouchableOpacity } from 'react-native';
import { useScrollToTop } from '@react-navigation/native';
import axios from 'axios';

import Header from '../../Headers/LoggedInHeader';

export default function ChooseAFramework({ navigation, host, userId, setUserId, userInfo, setUserInfo, setAppInfo, appUpdated, setAppUpdated }) {
    const ref = useRef(null);
    useScrollToTop(ref);

    function getCookie(name) {
        function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }

    useEffect(() => {
        if(getCookie('logged_in') == 'false') {
            return navigation.navigate('Home');
        } else {
            setUserId(getCookie('userId'));
        }
    });

    const createExpoApp = () => {
        axios
            .post(`${host}/api/post/app`, { userId: userId, isWebsiteToApp: false })
            .then(res => {
                // console.log("res.data: ", res.data);
                setAppInfo(res.data);
                document.cookie = `app_id = ${res.data.app_id}`;
                setAppUpdated(res.data.app_id);
                if(getCookie('app_id') == res.data.app_id) {
                    navigation.navigate('InApp');
                }
            })
            .catch(err => console.log(err))
    }

    const createFlutterApp = () => {
        axios
            .post(`${host}/api/flutter/app`, { userId: userId })
            .then(res => {
                // console.log("res.data: ", res.data);
                setAppInfo(res.data);
                document.cookie = `flutter_app_id = ${res.data.flutter_app_id}`;
                setAppUpdated(res.data.flutter_app_id);
                if(getCookie('flutter_app_id') == res.data.flutter_app_id) {
                    navigation.navigate('InAppFlutter');
                }
            })
            .catch(err => console.log(err))
    }

    const createWebsiteToAppApp = () => {
        axios
            .post(`${host}/api/post/app`, { userId: userId, isWebsiteToApp: true })
            .then(res => {
                // console.log("res.data: ", res.data);
                setAppInfo(res.data);
                document.cookie = `app_id = ${res.data.app_id}`;
                setAppUpdated(res.data.app_id);
                if(getCookie('app_id') == res.data.app_id) {
                    navigation.navigate('InAppWebsiteToApp');
                }
            })
            .catch(err => console.log(err))
    }

    return (
        <View style={styles.page}>
            <Header navigation={navigation} setUserId={setUserId} setAppInfo={setAppInfo}/>
            <View style={styles.body}>
                <Text style={styles.h1}>Is this a <Text style={styles.h1Bold}>React Native / Expo</Text> app? Or are you turning a <Text style={styles.h1Bold}>Website into an App</Text>? Choose an app type:</Text>
                <View className style={styles.flexRow}>
                    
                    <TouchableOpacity style={styles.frameworkCont} onPress={() => createExpoApp()}>
                        <Image
                            style={styles.icon}
                            source={require('../../assets/images/react-native-expo-icon.png')}
                            resizeMode='cover'
                        />
                        <Text style={styles.frameworkTitle}>React Native / Expo</Text>
                    </TouchableOpacity>
                    {/* <TouchableOpacity style={styles.frameworkCont} onPress={() => createFlutterApp()}>
                        <Image
                            style={styles.icon}
                            source={require('../../assets/images/flutter-icon.png')}
                            resizeMode='cover'
                        />
                        <Text style={styles.frameworkTitle}>Flutter</Text>
                    </TouchableOpacity> */}

                    {/* old way */}
                    {/* <TouchableOpacity style={styles.frameworkCont} onPress={() => createWebsiteToAppApp()}>
                        <Image
                            style={styles.icon}
                            source={require('../../assets/images/website-to-app-icon.png')}
                            resizeMode='cover'
                        />
                        <Text style={styles.frameworkTitle}>Website to App</Text>
                    </TouchableOpacity> */}

                    {/* new way that is a link */}
                    <a style={styles.frameworkCont} href='https://websitetoappconvert.com/'>
                        <Image
                            style={styles.icon}
                            source={require('../../assets/images/website-to-app-icon.png')}
                            resizeMode='cover'
                        />
                        <Text style={styles.frameworkTitle}>Website to App</Text>
                    </a>
                </View>
            </View>    
        </View>
    )
}

let flexRowJustify, appMR, appWH;

if(screen.width < 500) {
    flexRowJustify = 'space-between';
    appMR = 0;
} else {
    flexRowJustify = 'flex-start';
    appMR = 60;
}

if(screen.width > 400) {
    appWH = 175;
} else { appWH = 160 }

const styles = StyleSheet.create({
    page: {
        flex: 1, 
        height: '100%',
        minHeight: '100vh',
        width: '100%',
        minWidth: '100vw',
        backgroundColor: "rgb(250, 250, 250)",
    },
    body: {
        height: '100%',
        width: '100%',
        padding: '5%',
        paddingTop: 30
    },
    flexRow: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: flexRowJustify
    },
    h1: {
        fontFamily: 'Arial',
        fontWeight: 400,
        fontSize: 20,
        marginBottom: 30,
        paddingTop: 5,
        paddingBottom: 7,
    },
    h1Bold: {
        fontFamily: 'Arial',
        fontWeight: 700,
        fontSize: 20,
        marginBottom: 30,
        paddingTop: 5,
        paddingBottom: 7,
    },
    frameworkCont: {
        marginTop: 30,
        marginRight: appMR,
        textAlign: 'center',
        textDecorationLine: 'none'
    },
    frameworkTitle: {
        fontFamily: 'Arial',
        fontWeight: 700,
        fontSize: 16,
        marginBottom: 10
    },
    icon: {
        height: 200,
        width: 200,
        height: appWH,
        width: appWH,
        borderRadius: 20,
        boxShadow: 'rgb(214 214 214) 0px 2px 5px 2px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 15,
        backgroundColor: '#fff',
        marginBottom: 20
    }
})
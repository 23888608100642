import React, { useState } from 'react';

import AppNavigation from '../AppNavigation/AppNavigation';

export default function AppState({ host, userId, setUserId, expoToken }) {
    const [userInfo, setUserInfo] = useState({});
    const [validationCode, setValidationCode,] = useState(0);

    const [appInfo, setAppInfo] = useState({});
    const [appUpdated, setAppUpdated] = useState(0);

    return (
        <AppNavigation 
            host={host}
            userId={userId}
            setUserId={setUserId}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            validationCode={validationCode}
            setValidationCode={setValidationCode}
            expoToken={expoToken}  

            appInfo={appInfo}
            setAppInfo={setAppInfo}
            appUpdated={appUpdated}
            setAppUpdated={setAppUpdated}
        />
    );
}
import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Platform, TextInput } from 'react-native';
import axios from 'axios';

import Icon from 'react-native-vector-icons/MaterialIcons';

import DocsMenu from './Components/DocsMenu';

export default function IOSDocs({ host, appInfo, InAppNavigation, updateAppInfo }) {
    const { goToSend, setScreenName } = InAppNavigation;

    return (
        <View style={styles.body}>
            <DocsMenu InAppNavigation={InAppNavigation} />
            
            <Text style={styles.HeadingText}>Follow these steps to create an Android app and/or an iOS app to publish to the Google Play store and/or the Apple App Store:</Text>
            <Text style={styles.plainText}>Follow the <TouchableOpacity onPress={() => setScreenName('installDocs')}>'Start Here'</TouchableOpacity> instructions BEFORE creating an app to publish to the Google Play store and/or the Apple App Store.</Text>

            {/* <Text style={styles.mb}></Text>

            {Platform.OS === "web"
                ? <View style={styles.youTubeVideo}>
                    <Text style={styles.h2}>This video walks you through the setup guide below:</Text>
                    <iframe width="400" height="220" style={{ maxWidth: '100%' }} src="https://www.youtube.com/embed/7jXRDQ89rFk?rel=0" title="Native Notify Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen="allowfullscreen"></iframe>
                    
                    </View>
                : null 
            }    */}

            <View style={styles.dottedLine}></View>

            <Text style={styles.HeadingText}>Use EAS Build to create your app:</Text>
            <Text style={styles.boldText}>Step 1:</Text>
            <Text style={styles.plainText}>Type this into your Visual Studio Code terminal and click "return/enter" to install eas-cli on your computer:</Text>
            <Text style={styles.blackBacktext}>
                <Text>npm install -g eas-cli</Text>
            </Text>

            <Text style={styles.boldText}>Step 2:</Text>
            <Text style={styles.plainText}>Type this into your Visual Studio Code terminal, click "return/enter", and follow the instructions to create your Android and/or iOS app:</Text>
            <Text style={styles.blackBacktext}>
                <Text>eas build</Text>
            </Text>

            <Text style={styles.boldText}>Step 3:</Text>
            <Text style={styles.plainText}>Publish your Android app and/or your iOS app to one or both of the app stores below:</Text>

            <Text style={styles.boldText}>Publish an Android App:</Text>
            <Text style={styles.plainText}>
                Go to this link to create a Google Play store developer account if you want to publish an Android app: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://play.google.com/console/u/0/signup')}>https://play.google.com/console/u/0/signup</TouchableOpacity>
            </Text>
            <Text style={styles.boldText}>Publish an iOS App:</Text>
            <Text style={styles.mb}>
                Go to this link to create a Apple App Store developer account if you want to publish an iOS app: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://developer.apple.com/programs/enroll/')}>https://developer.apple.com/programs/enroll/</TouchableOpacity>
            </Text>
            <Text style={styles.plainText}>
                After you have created your Apple App Store developer account, you will need to download the Apple Transporter App to upload your app to your Apple App Store developer account. You can download the Transporter app here: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://apps.apple.com/us/app/transporter/id1450874784?mt=12')}>https://apps.apple.com/us/app/transporter/id1450874784?mt=12</TouchableOpacity>
            </Text>

            <View style={styles.dottedLine}></View>

            <Text style={styles.HeadingText}>Congratulations! </Text>
            <Text style={styles.mb}>Your app has been published to the Google Play store and/or the Apple App Store!</Text> 
            
            <Text style={styles.plainText}>Once your app is approved, you can send push notifications to your app using NativeNotify.com by clicking on the   <TouchableOpacity onPress={() => goToSend()}>
                <Icon
                    name="send"
                    size={20}
                    color={'#000'}
                    onPress={() => goToSend()}
                />
            </TouchableOpacity>   button at the top left of this page.</Text>
        </View>
    )
}

const styles = StyleSheet.create({
    body: {
        width: 'calc(100% - 60px)',
        paddingTop: 30,
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingBottom: 200,
    },
    flexRow: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    leftCont: {
        width: screen.width > 900 ? '48%' : '100%',
    },
    rightCont: {
        width: screen.width > 900 ? '48%' : '100%',
        backgroundColor: screen.width > 900 ? '#3675d420' : '#fff',
        padding: screen.width > 900 ? 15 : 0,
        borderRadius: screen.width > 900 ? 6 : 0
    },
    stickyRight: {
        position: screen.width > 900 ? 'sticky' : 'relative',
        top: screen.width > 900 ? 10 : null
    },

    buttonText: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 16,
        color: '#fff',
    },
    button: {
        width: 220,
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
    },
    HeadingTextTop: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 20,
        justifyContent: 'center',
        marginBottom: -5
    },
    HeadingTextBottom: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        marginBottom: 30,
        justifyContent: 'center'
    },
    HeadingText: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        // marginTop: 70,
        marginBottom: 10
    },
    HeadingText2: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        marginTop: 70,
        marginBottom: 10
    },
    h2: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 20,
        marginBottom: 10
    },
    boldText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginTop: 20,
        marginBottom: 5
    },
    boldTextTop: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        // marginTop: 20,
        marginBottom: 5
    },
    blackBacktext: {
        backgroundColor: "#1b1f23",
        color: "#fff",
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        padding: 20,
        borderRadius: 6,
        marginBottom: 5,
        display: "flex",
        flexDirection: "column"
    },
    blueText: {
        color: "rgb(54, 117, 212)"
    },
    medBlueText: {
        color: "rgb(81, 130, 204)"
    },
    dottedLine: {
        height: 1,
        width: "100%",
        borderTopWidth: 1,
        borderColor: "#000",
        borderStyle: "dotted",
        marginTop: 30,
        marginBottom: 30,
    },
    lightBlueText: {
        color: "rgb(144, 180, 218)"
    },
    plainText: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 5
    },
    purpleText: {
        color: "rgb(187, 86, 187)"
    },
    mb: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 20
    },
    mbSmall: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 5
    },
    tab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50
    },
    greentab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50,
        color: 'rgb(81, 167, 81)'
    },
    yellowText: {
        color: "rgb(222, 222, 160)"
    },
    greenText: {
        color: 'rgb(81, 167, 81)'
    },
    sendCont: {
        padding: 20,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 15
    },
    sendButton: {
        width: 220,
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
        marginTop: 5,
        // marginBottom: 70,
    },
    inputCont: {
        marginBottom: 20,
        width: '100%',
    },
    inputLarge: {
        width: '100%',
        padding: 12.5,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 15
    },
    orangeText: {
        color: "#c78c74"
    },
    container: {
        width: '100%',
        backgroundColor: '#fff',
        padding: 20,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        borderRadius: 6,
        marginBottom: 20
    },
    row: {
        flexDirection: "row",
        alignItems: 'center',
        marginTop: 10
    },
    rowText: {
        width: 270,
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 10,
        marginRight: 20
    },
    text: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 10,
    }
});
import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image, TextInput } from 'react-native';

import Icon from 'react-native-vector-icons/FontAwesome5';
import Icon2 from 'react-native-vector-icons/MaterialIcons';
import Icon3 from 'react-native-vector-icons/FontAwesome';

export default function HomeHeader({ navigation, setUserId, setAppInfo }) {
    return (
        <View style={styles.headerCont}>
            <TouchableOpacity 
                style={styles.smallCont} 
                onPress={() => {
                    setAppInfo({});
                    navigation.navigate('Dashboard');
                }}
            >
                <Image
                    style={styles.logo}
                    source={require('../assets/images/logo.png')}
                    resizeMode='cover'
                />
            </TouchableOpacity>
            <Icon
                name="question-circle"
                size={23}
                onPress={() => {
                    navigation.navigate('FAQ');
                }}
            />
            
            <Icon2
                name="settings"
                size={23}
                onPress={() => {
                    navigation.navigate('Settings');
                }}
            />

            <TouchableOpacity 
                style={styles.logoutButton} 
                onPress={() => {
                    var d = new Date();
                    d.setDate(d.getDate() - 1);
                    document.cookie = `logged_in = false; expires=${d};`;
                    document.cookie = `logged_in = false; domain=nativenotify.com; expires=${d};`;
                    document.cookie = `logged_in = false; domain=app.nativenotify.com; expires=${d};`;
                    document.cookie = `userId = 0; expires=${d};`;
                    document.cookie = `userId = 0; domain=nativenotify.com; expires=${d};`;
                    document.cookie = `userId = 0; domain=app.nativenotify.com; expires=${d};`;
                    document.cookie = `app_id = 0; expires=${d};`;
                    document.cookie = `app_id = 0; domain=nativenotify.com; expires=${d};`;
                    document.cookie = `app_id = 0; domain=app.nativenotify.com; expires=${d};`;
                    setUserId(0);
                    navigation.navigate('Login');
                }}
            >
                <Text style={styles.whiteText}>Logout</Text>
            </TouchableOpacity>
        </View>
    )
    
}

const styles = StyleSheet.create({
    headerCont: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 20,
        paddingLeft: '5%',
        paddingRight: '5%',
        flexWrap: 'wrap',
        boxShadow: 'rgb(214 214 214) 0px 2px 5px',
        backgroundColor: '#fff',
    },
    smallCont: {
        height: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    logo: {
        width: 25,
        height: 31
    },
    logoText: {
        fontSize: 20,
        fontFamily: 'Arial',
        fontWeight: 600,
        marginLeft: 20
    },
    logoutButton: {
        cursor: 'pointer',
        padding: 5,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: '#000',
        borderRadius: 3,
    },
    blackText: {
        color: '#000',
        fontFamily: 'Arial',
        fontWeight: 400,
    },
    whiteText: {
        color:'#fff',
        fontFamily: 'Arial',
        fontWeight: 400,
    },
    fingoBoxButton: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent:'center'
    }
});
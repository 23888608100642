import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Button, TextInput } from 'react-native';
import axios from 'axios';
import StripeCheckout from 'react-stripe-checkout';

import Header from '../../../Headers/HomeHeader';
import StripeButton from './Components/StripeButton';

export default function SignUpOne({ navigation, host, setUserInfo, setValidationCode, setUserId }) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handlePost = (token) => {
        console.log("handlePost fired!", token);
        setUserInfo({ name, email, password });

        if(name && email && password) {
            axios
                .post(`${host}/api/auth/register`, { 
                    name: name,
                    email: email,
                    password: password
                })
                .then(res => {
                    if(res.data === "Email in use.") {
                        alert("Sorry, that email is already associated with a Native Notify account. Please try again with another email or login.")
                    } else {
                        setUserId(res.data.user_id);
                        document.cookie = `userId = ${res.data.user_id}; max-age=31536000;`;
                        document.cookie = `userId = ${res.data.user_id}; domain=nativenotify.com; max-age=31536000;`;
                        document.cookie = `userId = ${res.data.user_id}; domain=app.nativenotify.com; max-age=31536000;`;
                        document.cookie = `logged_in = true; max-age=31536000;`;
                        document.cookie = `logged_in = true; domain=nativenotify.com; max-age=31536000;`;
                        document.cookie = `logged_in = true; domain=app.nativenotify.com; max-age=31536000;`;
                        setUserId(res.data.user_id);
                        navigation.navigate('Dashboard');
                    } 
                })
                .catch(err => {
                    console.log(err);
                    alert('Sorry. Our servers seem to be down. Please try again later.')
                }) 
        } else {
            alert('Please provide a Name, Email, and Password before clicking the "Sign Up Free" button.')
        }
        

        // axios
        //     .get(`${host}/api/auth/sendCode/${email}`)
        //     .then(res => {
        //         console.log("res.data: ", res.data);

        //         if(res.data === "Email in use.") {
        //             alert("Sorry, that email is already associated with a Native Notify account. Please try again with another email or login.")
        //         } else {
        //             setValidationCode(res.data);
        //             navigation.navigate('SignUpTwo');
        //         }
        //     })
        //     .catch(err => {
        //         console.log(err);
        //         alert('Sorry. Our servers seem to be down. Please try again later.')
        //     })
    }

    return (
        <View style={styles.page}>
            <Header navigation={navigation} />
            <View style={styles.body}>
                <Text style={styles.headerText}>Sign Up Free</Text>
                <Text style={styles.subtitleText}>No credit card required</Text>
                <TextInput 
                    style={styles.inputLarge}
                    onChangeText={(text) => setName(text)}
                    placeholder={"Name"}
                    placeholderTextColor={"#6e6d6c"}
                    value={name}
                />
                <TextInput 
                    style={styles.inputLarge}
                    onChangeText={(text) => setEmail(text.toLowerCase())}
                    placeholder={"Email"}
                    placeholderTextColor={"#6e6d6c"}
                    value={email}
                />
                <TextInput 
                    style={styles.inputLarge}
                    onChangeText={(text) => setPassword(text)}
                    placeholder={"Password"}
                    placeholderTextColor={"#6e6d6c"}
                    value={password}
                    secureTextEntry={true}
                />
                
                <View style={styles.signUpCont}>
                    {/* <StripeButton onToken={onToken} />  */}
                    <TouchableOpacity onPress={() => handlePost()}><Text style={styles.button}>Sign Up Free</Text></TouchableOpacity>
                </View>        
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    page: {
        flex: 1, 
        minHeight: 500,
        backgroundColor: "#fff",
        alignItems: "center",
        backgroundColor: "#3576d4",
    },
    headerText: {
        width: "90%",
        textAlign: "left",
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 30,
    },
    subtitleText: {
        width: "100%",
        fontFamily: "Arial",
        fontWeight: 400,
        color: "#6e6d6c",
        marginBottom: 20,
    },
    body: {
        width: "95%",
        maxWidth: 500,
        maxHeight: 700,
        padding: "3%",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        marginTop: 60,
    },

    birthdayCont: {
        width: "100%",
    },
    button: {
        width: 220,
        padding: 7.5,
        backgroundColor: "#06bd43",
        borderRadius: 6,
        marginBottom: 20,
        fontFamily: "Arial",
        fontWeight: 700,
        color: "white",
        fontSize: 20,
        textAlign: "center"
    },
    chosenGender: {
        padding: 5,
        paddingLeft: 20,
        paddingRight: 20,
        fontFamily: "Arial",
        fontWeight: 400,
        backgroundColor: "#00BBF9",
        color: "white",
        borderRadius: 20,
        marginRight: 10,
    },
    gender: {
        padding: 5,
        paddingLeft: 20,
        paddingRight: 20,
        fontFamily: "Arial",
        fontWeight: 400,
        backgroundColor: "black",
        color: "white",
        borderRadius: 20,
        marginRight: 10,
    },
    inputLarge: {
        width: "100%",
        padding: 12.5,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 15
    },
    rowCont: {
        flexDirection: "row",
        alignItems: "center",
        marginTop: 5
    },
    rowContBetween: {
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
    signUpCont: {
        width: "100%",
        alignItems: "center",
        marginTop: 25
    },
    stripeCheckout: {
        width: "100%",
        // maxWidth: 300,
        color: "#fff",
        fontSize: 20,
        fontWeight: "700",
        borderRadius: 6,
        marginTop: 30,
    },
    text: {
        width: "100%",
        fontFamily: "Arial",
        fontWeight: 400,
        color: "#6e6d6c",
    }
});
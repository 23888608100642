import React, { useState, useEffect } from 'react';
import { ActivityIndicator, View, Text, TouchableOpacity, StyleSheet, TextInput } from 'react-native';
import axios from 'axios';

import Header from '../../../Headers/HomeHeader';

export default function SignUpTwo({ navigation, host, validationCode, userInfo, setUserId }) {
    const [showLoading, setShowLoading] = useState(false);
    const [confirmValCode, setConfirmValCode] = useState('');

    const handlePost = () => {
        if(confirmValCode == validationCode) {
            setShowLoading(true);

            axios
                .post(`${host}/api/auth/register`, { 
                    name: userInfo.name,
                    email: userInfo.email,
                    password: userInfo.password
                })
                .then(res => {
                    setUserId(res.data.user_id);
                    document.cookie = `userId = ${res.data.user_id}; max-age=31536000;`;
                    document.cookie = `userId = ${res.data.user_id}; domain=nativenotify.com; max-age=31536000;`;
                    document.cookie = `userId = ${res.data.user_id}; domain=app.nativenotify.com; max-age=31536000;`;
                    document.cookie = `logged_in = true; max-age=31536000;`;
                    document.cookie = `logged_in = true; domain=nativenotify.com; max-age=31536000;`;
                    document.cookie = `logged_in = true; domain=app.nativenotify.com; max-age=31536000;`;
                    setUserId(res.data.user_id);
                    setShowLoading(false);
                    navigation.navigate('Dashboard');
                })
                .catch(err => console.log(err))  
        } else {
            alert("Sorry, that verification code does not match our records. Please, try again.")
        }
    }

    if(showLoading) {
        return <ActivityIndicator size="large" color="rgb(54, 117, 212)" />
    } else {
        return (
            <View style={styles.page}>
                <Header navigation={navigation} />
                <View style={styles.body}>
                    <Text style={styles.headerText}>Step 2 of 2: </Text>
                    <Text style={styles.subText}>We sent a verification code to your email. Type that code below:</Text>
                    <TextInput 
                        style={styles.inputLarge}
                        onChangeText={(text) => setConfirmValCode(text)}
                        placeholder={"Type your verification code here..."}
                        placeholderTextColor={"#6e6d6c"}
                        value={confirmValCode}
                    />
                    <View style={styles.signUpCont}>
                        <TouchableOpacity onPress={() => handlePost()}><Text style={styles.button}>Submit</Text></TouchableOpacity>
                    </View> 
                </View>
                
            </View>
        )
    }
}

const styles = StyleSheet.create({
    page: {
        flex: 1, 
        height: "100%",
        backgroundColor: "#fff",
        alignItems: "center",
        backgroundColor: "#3576d4",
    },
    body: {
        width: "95%",
        maxWidth: 500,
        maxHeight: 700,
        padding: "3%",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        marginTop: 60,
    },
    headerText: {
        width: "100%",
        textAlign: "left",
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        marginBottom: 10,
    },
    subText: {
        width: "100%",
        textAlign: "left",
        fontFamily: "Arial",
        fontWeight: 500,
        fontSize: 20,
        marginBottom: 30,
    },

    button: {
        width: 220,
        padding: 7.5,
        backgroundColor: "#06bd43",
        borderRadius: 6,
        marginBottom: 20,
        fontFamily: "Arial",
        fontWeight: 700,
        color: "white",
        fontSize: 20,
        textAlign: "center"
    },
    inputLarge: {
        width: "100%",
        padding: 12.5,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 15
    },
    signUpCont: {
        width: "100%",
        alignItems: "center",
        marginTop: 25
    },
    text: {
        width: "100%",
        fontFamily: "Arial",
        fontWeight: 400,
        color: "#6e6d6c",
    }
});
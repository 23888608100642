import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image, TextInput } from 'react-native';
import axios from 'axios';

import Icon from 'react-native-vector-icons/Ionicons';
import Icon2 from 'react-native-vector-icons/FontAwesome5';

export default function NotificationHeader({ navigation, host, appInfo, setAppInfo, setAppUpdated }) {
    const [appName, setAppName] = useState("");

    useEffect(() => {
        // console.log(appInfo.app_name);
        setAppName(appInfo.app_name);
    }, [appInfo])

    let timer;
    const updateAppName = (text) => {
        axios
            .put(`${host}/api/app/name`, { app_id: appInfo.app_id, app_name: text })
            .then(res => {
                // console.log(res.data);
                setAppInfo(res.data);
                alert("Title was successfully updated.")
            })
            .catch(err => console.log(err)) 
    }

    return (
        <View style={styles.headerCont}>
            <View style={styles.smallCont}>
                <Icon
                    name="home"
                    size={20}
                    onPress={() => {
                        setAppUpdated(0);
                        navigation.navigate('Dashboard');
                    }}
                />
                <TextInput 
                    style={styles.inputLarge}
                    onChangeText={(text) => setAppName(text)}
                    placeholderTextColor={"#6e6d6c"}
                    value={appName}
                />
                <TouchableOpacity 
                    style={styles.saveButton} 
                    onPress={() => updateAppName(appName)}
                >
                    <Text style={styles.whiteText}>Update Title</Text>
                </TouchableOpacity>
            </View>
            <Icon2
                name="question-circle"
                size={23}
                onPress={() => {
                    setAppUpdated(0)
                    navigation.navigate('FAQ');
                }}
            />    
        </View>
    )
}

const styles = StyleSheet.create({
    headerCont: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 20,
        paddingLeft: '5%',
        paddingRight: '5%',
        flexWrap: 'wrap',
        boxShadow: 'rgb(214 214 214) 0px 2px 5px',
        backgroundColor: '#fff',
    },
    inputLarge: {
        width: screen.width < 350 ? 100 : 160,
        fontFamily: 'Arial',
        fontWeight: 600,
        padding: 5,
        paddingLeft: 10,
        paddingRight: 10,
        borderWidth: 1,
        borderRightWidth: 0,
        borderColor: "#99999960",
        backgroundColor: "#fff",
        borderTopLeftRadius: 3,
        borderBottomLeftRadius: 3,
        marginLeft: 30
    },
    smallCont: {
        height: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth:'90%'
    },
    logoText: {
        fontSize: 20,
        fontFamily: 'Arial',
        fontWeight: 600,
    },
    saveButton: {
        cursor: 'pointer',
        padding: 5,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: '#000',
        borderTopRightRadius: 3,
        borderBottomRightRadius: 3,
        // marginLeft: 5,
        borderWidth: 1,
        borderColor: "#000",
    },
    signUpButton: {
        cursor: 'pointer',
        padding: 5,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: '#000',
        borderRadius: 3,
        borderWidth: 1,
        borderColor: "#000",
    },
    blackText: {
        color: '#000',
        fontFamily: 'Arial',
        fontWeight: 400
    },
    whiteText: {
        color:'#fff',
        fontFamily: 'Arial',
        fontWeight: 400,
    }
});
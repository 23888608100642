import React, { useState, useEffect, useRef } from 'react';
import { View, TextInput, StyleSheet, Text, TouchableOpacity, ScrollView } from 'react-native';
import axios from 'axios';

export default function ScheduleNotification({ host, userId, appInfo }) {
    const [scheduledNotifications, setScheduledNotifications] = useState([]);
    const [showDataInput, setShowDataInput] = useState(false);
    const [showSee, setShowSee] = useState(false);
    const [tZ, setTZ] = useState([]);

    const [timezone, setTimezone] = useState('');
    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const [day, setDay] = useState('');
    const [hour, setHour] = useState('');
    const [minute, setMinute] = useState('');
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [bigPictureURL, setBigPictureURL] = useState('');
    const [pushData, setPushData] = useState('');

    const scrollRef = useRef();

    useEffect(() => {
        setTZ(tZArray.sort());

        axios
            .get(`${host}/api/scheduled/notifications/${userId}/${appInfo.app_id}`)
            .then(res => {
                console.log(res.data);

                setScheduledNotifications(res.data);
            })
            .catch(err => console.log(err))
        window.scroll({ top: 0, left: 0, behavior: 'instant' });
    }, [appInfo])

    const handleSelectTimezone = (timeZ) => {
        console.log("timezone: ", timeZ.target.value);
        setTimezone(timeZ.target.value);
    }

    const handleSelectDate = (event) => {
        const dateArray = event.target.value.split("-");
        console.log("dateArray: ", dateArray);

        setYear(dateArray[0]);
        setMonth(dateArray[1]);
        setDay(dateArray[2]);
    }

    const handleSelectTime = (event) => {
        const timeArray = event.target.value.split(":");
        console.log("timeArray: ", timeArray);

        setHour(timeArray[0]);
        setMinute(timeArray[1]);
    }

    const scheduleNotification = () => {
        if (!timezone || !year || !month || !day || !hour || !minute || !title || !body) {
            return alert("One or more of the required inputs are empty. Please, fill in all the required inputs before scheduling a push notification.");
        }

        let utc = new Date(year, month - 1, day, hour, minute, 0);

        let utcDate = new Date(utc.toLocaleString('en-US', { timeZone: "UTC" }));
        let tzDate = new Date(utc.toLocaleString('en-US', { timeZone: timezone }));
        let offset = utcDate.getTime() - tzDate.getTime();

        utc.setTime(utc.getTime() + offset);

        console.log("notification scheduled: ", utc, utc.getFullYear().toString(), utc.getMonth().toString(), utc.getDate().toString(), utc.getHours().toString(), utc.getMinutes().toString(), timezone, year, month, day, hour, minute, title, body, bigPictureURL, pushData);

        let sendConfirm = confirm("Are you ready to schedule your push notification? This cannot be undone.");

        if (sendConfirm) {
            axios
                .post(`${host}/api/schedule/notification`, {
                    userId, appId: appInfo.app_id, appToken: appInfo.app_token,
                    title, body, bigPictureURL, pushData,
                    timezone, year, month, day, hour, minute,
                    utcYear: utc.getFullYear().toString(), utcMonth: utc.getMonth().toString(),
                    utcDay: utc.getDate().toString(), utcHour: utc.getHours().toString(), utcMinute: utc.getMinutes().toString()
                })
                .then((res) => {
                    // console.log("res.data: ", res.data);
                    alert('Notification successfully scheduled!');

                    setTitle('');
                    setBody('');
                    setBigPictureURL('');
                    setPushData('');

                    axios
                        .get(`${host}/api/scheduled/notifications/${userId}/${appInfo.app_id}`)
                        .then(res => {
                            console.log(res.data);

                            setScheduledNotifications(res.data);
                        })
                        .catch(err => console.log(err))
                })
                .catch(err => console.log(err))
        }

    }

    return (
        <ScrollView style={styles.body} ref={scrollRef}>
            <Text style={styles.HeadingTextBold}>Schedule a Push Notification to be Sent in the Future:</Text>
            <View style={styles.inputCont}>
                <Text style={styles.text}>Timezone:</Text>
                <View style={styles.inputSmall}>
                    <select value={timezone} onChange={handleSelectTimezone} >
                        {tZ.map((e, i) => {
                            return <option value={e} key={i}>{e}</option>
                        })}
                    </select>
                </View>
            </View>
            <View style={styles.inputCont}>
                <Text style={styles.text}>Date:</Text>
                <View style={styles.inputSmall}>
                    <input
                        type="date"
                        min={new Date().toISOString().split("T")[0]}
                        onChange={handleSelectDate} />
                </View>
            </View>
            <View style={styles.inputCont}>
                <Text style={styles.text}>Time:</Text>
                <View style={styles.inputSmall}>
                    <input type="time" onChange={handleSelectTime} />
                </View>
            </View>
            <View style={styles.inputCont}>
                <Text style={styles.text}>Notification Title:</Text>
                <TextInput
                    style={styles.inputLarge}
                    onChangeText={(text) => setTitle(text)}
                    placeholder={"Type Notification Title here..."}
                    placeholderTextColor={"#6e6d6c"}
                    value={title}
                />
            </View>
            <View style={styles.inputCont}>
                <Text style={styles.text}>Notification Message:</Text>
                <TextInput
                    style={styles.inputLarge}
                    onChangeText={(text) => setBody(text)}
                    placeholder={"Type Notification Message here..."}
                    placeholderTextColor={"#6e6d6c"}
                    value={body}
                    multiline={true}
                />
            </View>

            <TouchableOpacity style={styles.advancedText} onPress={() => setShowDataInput(!showDataInput)}>
                <Text>Advanced Settings (optional)</Text> {showDataInput ? <Text>{"   "}&#8593;</Text> : <Text>{"   "}&#8595;</Text>}
            </TouchableOpacity>
            {showDataInput
                ? <View style={styles.inputCont}>
                    <Text style={styles.dataObjText}>Big Picture URL:</Text>
                    <Text style={styles.seeText}>(Big Pictures only work with Android in production mode.)</Text>
                    <TextInput
                        style={styles.inputLarge}
                        onChangeText={(text) => setBigPictureURL(text)}
                        placeholder={'Type the URL to the big picture you would like to appear in your Android push notification. (Big pictures do not work with iOS.)'}
                        placeholderTextColor={"#6e6d6c"}
                        value={bigPictureURL}
                        multiline={true}
                    />

                    <Text style={styles.dataObjText}>Data Object:</Text>
                    <Text style={styles.seeText}>(Push data objects will not work in Android production if you send a Big Picture with your push notification. Leave the Big Picture URL input blank if you need to send a push data object with your push notification.)</Text>
                    <TextInput
                        style={styles.inputLarge}
                        onChangeText={(text) => setPushData(text)}
                        placeholder={'Type data object here in this format: { "propertyName":"propertyValue" }'}
                        placeholderTextColor={"#6e6d6c"}
                        value={pushData}
                        multiline={true}
                    />

                    <TouchableOpacity style={styles.seeText} onPress={() => setShowSee(!showSee)}>
                        <Text>See how Data Objects work</Text>
                        {showSee ? <Text>{"   "}&#8593;</Text> : <Text>{"   "}&#8595;</Text>}
                    </TouchableOpacity>
                    {showSee
                        ? <View style={styles.blueBackCont}>
                            <Text style={styles.boldText}>Follow these steps to see push data objects in your app:</Text>

                            <Text style={styles.boldText}>Step 1:</Text>
                            <Text style={styles.blackBacktext}>
                                <Text style={styles.mbSmall}>npm uninstall native-notify</Text>
                                <Text style={styles.mbSmall}>npm install native-notify</Text>
                                <Text>npx expo install expo-device expo-notifications expo-constants</Text>
                            </Text>

                            <Text style={styles.boldText}>Step 2:</Text>
                            <Text style={styles.plainText}>Make sure these imports are included at the top of your App.js screen:</Text>
                            <Text style={styles.blackBacktext}>
                                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>React, {"{ useState, useEffect }"} </Text><Text style={styles.purpleText}>from</Text> 'react';</Text>
                                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>{"{ getPushDataObject }"} </Text><Text style={styles.purpleText}>from</Text> 'native-notify';</Text>
                            </Text>

                            <Text style={styles.boldText}>Step 3:</Text>
                            <Text style={styles.plainText}>Make sure you are using a Hook function as your App function. Here is an example:</Text>
                            <Text style={styles.blackBacktext}>
                                <Text><Text style={styles.purpleText}>export default</Text> <Text style={styles.medBlueText}>function</Text> <Text style={styles.yellowText}>App</Text>() {"{"}</Text>
                                <Text>     ...</Text>
                                <Text>{"}"}</Text>
                            </Text>
                            <Text style={styles.plainText}>
                                This link explains Hooks in detail: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://reactjs.org/docs/hooks-intro.html')}>https://reactjs.org/docs/hooks-intro.html</TouchableOpacity>
                            </Text>

                            <Text style={styles.boldText}>Step 4:</Text>
                            <Text style={styles.plainText}>Inside of your App function, paste this code:</Text>
                            <Text style={styles.blackBacktext}>
                                <Text><Text style={styles.blueText}>let</Text> <Text style={styles.lightBlueText}>pushDataObject</Text> = <Text style={styles.yellowText}>getPushDataObject</Text>();</Text>
                            </Text>

                            <Text style={styles.boldText}>Step 5:</Text>
                            <Text style={styles.plainText}>Inside of your App function, create a 'useEffect' function if you have not already. You can see the value of your Push Data Object in the useEffect function whenever the user taps on your push notification like this:</Text>
                            <Text style={styles.blackBacktext}>
                                <Text><Text style={styles.yellowText}>useEffect</Text>(() => {"{"}</Text>
                                <Text>     console.log(<Text style={styles.lightBlueText}>pushDataObject</Text>);</Text>
                                <Text>{"});"}</Text>
                            </Text>
                            <Text style={styles.plainText}>
                                This link explains how 'useEffect' works in detail: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://reactjs.org/docs/hooks-effect.html')}>https://reactjs.org/docs/hooks-effect.html</TouchableOpacity>
                            </Text>

                            <View style={styles.dottedLine}></View>

                            <Text style={styles.boldTextTop}>Here is an example of an App.js component with Native Notify Push Data Object code included:</Text>
                            <Text style={styles.blackBacktext}>
                                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>React, {"{ useState, useEffect }"} </Text><Text style={styles.purpleText}>from</Text> 'react';</Text>
                                <Text style={styles.mb}><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>{"registerNNPushToken, { getPushDataObject }"}</Text> <Text style={styles.purpleText}>from</Text> 'native-notify';</Text>

                                <Text><Text style={styles.purpleText}>export default</Text> <Text style={styles.medBlueText}>function</Text> <Text style={styles.yellowText}>App</Text>() {"{"}</Text>
                                <Text style={styles.mb}>     <Text style={styles.yellowText}>registerNNPushToken</Text>({appInfo.app_id}, '{appInfo.app_token}');</Text>

                                <Text style={styles.mb}>     <Text style={styles.blueText}>let</Text> <Text style={styles.lightBlueText}>pushDataObject</Text> = <Text style={styles.yellowText}>getPushDataObject</Text>();</Text>

                                <Text>     <Text style={styles.yellowText}>useEffect</Text>(() => {"{"}</Text>
                                <Text>          console.log(<Text style={styles.lightBlueText}>pushDataObject</Text>);</Text>
                                <Text style={styles.mb}>     {"});"}</Text>

                                <Text>     <Text style={styles.purpleText}>return</Text> (</Text>
                                <Text style={styles.tab2}>...</Text>
                                <Text>     )</Text>
                                <Text>{"}"}</Text>
                            </Text>

                        </View>
                        : null
                    }
                </View>
                : null
            }

            <TouchableOpacity style={styles.sendButton} onPress={() => scheduleNotification()}>
                <Text style={styles.buttonText}>Schedule Push Notification</Text>
            </TouchableOpacity>

            <Text style={styles.HeadingText}>Did you know you can send emojis in your push notifications? A collection of emojis can be found here: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://emojiterra.com')}><Text>https://emojiterra.com</Text></TouchableOpacity>.</Text>

            <Text style={styles.text}>Scheduled Notifications:</Text>
            {scheduledNotifications.map((e, i) => {
                return (
                    <View style={styles.recCont} key={i}>
                        <Text style={styles.recText}>
                            <Text style={styles.recTextBold}>
                                Timezone: {' '}
                            </Text>
                            {e.scheduled_timezone}
                        </Text>
                        <Text style={styles.recText}>
                            <Text style={styles.recTextBold}>
                                Date (YYYY-MM-DD): {' '}
                            </Text>
                            {e.scheduled_year}-{e.scheduled_month}-{e.scheduled_day}
                        </Text>
                        <Text style={styles.recText}>
                            <Text style={styles.recTextBold}>
                                Time: {' '}
                            </Text>
                            {e.scheduled_hour}:{e.scheduled_minute}
                        </Text>
                        <Text style={styles.recText}>
                            <Text style={styles.recTextBold}>
                                Title: {' '}
                            </Text>
                            {e.notification_title}
                        </Text>
                        <Text style={styles.recText}>
                            <Text style={styles.recTextBold}>
                                Message: {' '}
                            </Text>
                            {e.notification_message}
                        </Text>
                        <Text style={styles.recText}>
                            <Text style={styles.recTextBold}>
                                Big Picture URL: {' '}
                            </Text>
                            {e.notification_big_picture_url ? e.notification_big_picture_url : 'null'}
                        </Text>
                        <Text style={styles.recText}>
                            <Text style={styles.recTextBold}>
                                Push Data Object: {' '}
                            </Text>
                            {e.notification_push_data_object ? e.notification_push_data_object : 'null'}
                        </Text>
                    </View>
                )
            })}
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    body: {
        width: '85%',
        paddingTop: 30,
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingBottom: 100
    },
    HeadingText: {
        fontFamily: "Arial",
        fontSize: 16,
        marginBottom: 30
    },
    HeadingTextBold: {
        fontFamily: "Arial",
        fontSize: 20,
        fontWeight: 700,
        marginBottom: 30
    },
    plainText: {
        fontFamily: "Arial",
        fontSize: 16,
        marginBottom: 5
    },
    buttonText: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 16,
        color: '#fff',
    },
    guideButton: {
        width: 150,
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
        marginBottom: 70,
    },
    sendButton: {
        width: 240,
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
        marginTop: 30,
        marginBottom: 70,
    },
    showAPIButton: {
        width: 220,
        backgroundColor: 'black',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
        marginTop: 0,
        marginBottom: 30,
    },
    inputCont: {
        marginBottom: 20,
        width: '100%',
    },
    inputLarge: {
        width: '100%',
        padding: 12.5,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 15
    },
    inputSmall: {
        width: 120
    },

    recCont: {
        width: '100%',
        backgroundColor: '#fff',
        marginTop: 10,
        marginBottom: 10,
        padding: 20,
        paddingBottom: 15,
        borderRadius: 6,
        flexWrap: 'wrap',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
    },
    recText: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 14,
        marginBottom: 5,
    },
    recTextBold: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 5,
    },

    text: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginBottom: 5
    },
    blueText: {
        color: "rgb(54, 117, 212)"
    },
    advancedText: {
        flexDirection: 'row',
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 14,
        marginTop: -10,
        marginBottom: 10
    },
    dataObjText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginTop: 15,
        marginBottom: 5
    },
    seeText: {
        flexDirection: 'row',
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 14,
        marginBottom: 10,
    },
    blackBacktext: {
        backgroundColor: "#1b1f23",
        color: "#fff",
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        padding: 20,
        borderRadius: 6,
        marginBottom: 5,
        display: "flex",
        flexDirection: "column"
    },
    tab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50
    },
    greentab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50,
        color: 'rgb(81, 167, 81)'
    },
    yellowText: {
        color: "rgb(222, 222, 160)"
    },
    mb: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 20
    },
    lightBlueText: {
        color: "rgb(144, 180, 218)"
    },
    boldText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginTop: 20,
        marginBottom: 5
    },
    boldTextTop: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        // marginTop: 20,
        marginBottom: 5
    },
    purpleText: {
        color: "rgb(187, 86, 187)"
    },
    blueBackCont: {
        backgroundColor: '#3675d420',
        padding: 15,
        borderRadius: 6
    },
    dottedLine: {
        height: 1,
        width: "100%",
        borderTopWidth: 1,
        borderColor: "#000",
        borderStyle: "dotted",
        marginTop: 30,
        marginBottom: 30,
    }
});

var tZArray = [
    'Europe/Andorra',
    'Asia/Dubai',
    'Asia/Kabul',
    'Europe/Tirane',
    'Asia/Yerevan',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville',
    'Antarctica/Mawson',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Cordoba',
    'America/Argentina/Salta',
    'America/Argentina/Jujuy',
    'America/Argentina/Tucuman',
    'America/Argentina/Catamarca',
    'America/Argentina/La_Rioja',
    'America/Argentina/San_Juan',
    'America/Argentina/Mendoza',
    'America/Argentina/San_Luis',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Ushuaia',
    'Pacific/Pago_Pago',
    'Europe/Vienna',
    'Australia/Lord_Howe',
    'Antarctica/Macquarie',
    'Australia/Hobart',
    'Australia/Currie',
    'Australia/Melbourne',
    'Australia/Sydney',
    'Australia/Broken_Hill',
    'Australia/Brisbane',
    'Australia/Lindeman',
    'Australia/Adelaide',
    'Australia/Darwin',
    'Australia/Perth',
    'Australia/Eucla',
    'Asia/Baku',
    'America/Barbados',
    'Asia/Dhaka',
    'Europe/Brussels',
    'Europe/Sofia',
    'Atlantic/Bermuda',
    'Asia/Brunei',
    'America/La_Paz',
    'America/Noronha',
    'America/Belem',
    'America/Fortaleza',
    'America/Recife',
    'America/Araguaina',
    'America/Maceio',
    'America/Bahia',
    'America/Sao_Paulo',
    'America/Campo_Grande',
    'America/Cuiaba',
    'America/Santarem',
    'America/Porto_Velho',
    'America/Boa_Vista',
    'America/Manaus',
    'America/Eirunepe',
    'America/Rio_Branco',
    'America/Nassau',
    'Asia/Thimphu',
    'Europe/Minsk',
    'America/Belize',
    'America/St_Johns',
    'America/Halifax',
    'America/Glace_Bay',
    'America/Moncton',
    'America/Goose_Bay',
    'America/Blanc-Sablon',
    'America/Toronto',
    'America/Nipigon',
    'America/Thunder_Bay',
    'America/Iqaluit',
    'America/Pangnirtung',
    'America/Atikokan',
    'America/Winnipeg',
    'America/Rainy_River',
    'America/Resolute',
    'America/Rankin_Inlet',
    'America/Regina',
    'America/Swift_Current',
    'America/Edmonton',
    'America/Cambridge_Bay',
    'America/Yellowknife',
    'America/Inuvik',
    'America/Creston',
    'America/Dawson_Creek',
    'America/Fort_Nelson',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Dawson',
    'Indian/Cocos',
    'Europe/Zurich',
    'Africa/Abidjan',
    'Pacific/Rarotonga',
    'America/Santiago',
    'America/Punta_Arenas',
    'Pacific/Easter',
    'Asia/Shanghai',
    'Asia/Urumqi',
    'America/Bogota',
    'America/Costa_Rica',
    'America/Havana',
    'Atlantic/Cape_Verde',
    'America/Curacao',
    'Indian/Christmas',
    'Asia/Nicosia',
    'Asia/Famagusta',
    'Europe/Prague',
    'Europe/Berlin',
    'Europe/Copenhagen',
    'America/Santo_Domingo',
    'Africa/Algiers',
    'America/Guayaquil',
    'Pacific/Galapagos',
    'Europe/Tallinn',
    'Africa/Cairo',
    'Africa/El_Aaiun',
    'Europe/Madrid',
    'Africa/Ceuta',
    'Atlantic/Canary',
    'Europe/Helsinki',
    'Pacific/Fiji',
    'Atlantic/Stanley',
    'Pacific/Chuuk',
    'Pacific/Pohnpei',
    'Pacific/Kosrae',
    'Atlantic/Faroe',
    'Europe/Paris',
    'Europe/London',
    'Asia/Tbilisi',
    'America/Cayenne',
    'Africa/Accra',
    'Europe/Gibraltar',
    'America/Godthab',
    'America/Danmarkshavn',
    'America/Scoresbysund',
    'America/Thule',
    'Europe/Athens',
    'Atlantic/South_Georgia',
    'America/Guatemala',
    'Pacific/Guam',
    'Africa/Bissau',
    'America/Guyana',
    'Asia/Hong_Kong',
    'America/Tegucigalpa',
    'America/Port-au-Prince',
    'Europe/Budapest',
    'Asia/Jakarta',
    'Asia/Pontianak',
    'Asia/Makassar',
    'Asia/Jayapura',
    'Europe/Dublin',
    'Asia/Jerusalem',
    'Asia/Kolkata',
    'Indian/Chagos',
    'Asia/Baghdad',
    'Asia/Tehran',
    'Atlantic/Reykjavik',
    'Europe/Rome',
    'America/Jamaica',
    'Asia/Amman',
    'Asia/Tokyo',
    'Africa/Nairobi',
    'Asia/Bishkek',
    'Pacific/Tarawa',
    'Pacific/Enderbury',
    'Pacific/Kiritimati',
    'Asia/Pyongyang',
    'Asia/Seoul',
    'Asia/Almaty',
    'Asia/Qyzylorda',
    'Asia/Qostanay',
    'Asia/Aqtobe',
    'Asia/Aqtau',
    'Asia/Atyrau',
    'Asia/Oral',
    'Asia/Beirut',
    'Asia/Colombo',
    'Africa/Monrovia',
    'Europe/Vilnius',
    'Europe/Luxembourg',
    'Europe/Riga',
    'Africa/Tripoli',
    'Africa/Casablanca',
    'Europe/Monaco',
    'Europe/Chisinau',
    'Pacific/Majuro',
    'Pacific/Kwajalein',
    'Asia/Yangon',
    'Asia/Ulaanbaatar',
    'Asia/Hovd',
    'Asia/Choibalsan',
    'Asia/Macau',
    'America/Martinique',
    'Europe/Malta',
    'Indian/Mauritius',
    'Indian/Maldives',
    'America/Mexico_City',
    'America/Cancun',
    'America/Merida',
    'America/Monterrey',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Chihuahua',
    'America/Ojinaga',
    'America/Hermosillo',
    'America/Tijuana',
    'America/Bahia_Banderas',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Africa/Maputo',
    'Africa/Windhoek',
    'Pacific/Noumea',
    'Pacific/Norfolk',
    'Africa/Lagos',
    'America/Managua',
    'Europe/Amsterdam',
    'Europe/Oslo',
    'Asia/Kathmandu',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Auckland',
    'Pacific/Chatham',
    'America/Panama',
    'America/Lima',
    'Pacific/Tahiti',
    'Pacific/Marquesas',
    'Pacific/Gambier',
    'Pacific/Port_Moresby',
    'Pacific/Bougainville',
    'Asia/Manila',
    'Asia/Karachi',
    'Europe/Warsaw',
    'America/Miquelon',
    'Pacific/Pitcairn',
    'America/Puerto_Rico',
    'Asia/Gaza',
    'Asia/Hebron',
    'Europe/Lisbon',
    'Atlantic/Madeira',
    'Atlantic/Azores',
    'Pacific/Palau',
    'America/Asuncion',
    'Asia/Qatar',
    'Indian/Reunion',
    'Europe/Bucharest',
    'Europe/Belgrade',
    'Europe/Kaliningrad',
    'Europe/Moscow',
    'Europe/Simferopol',
    'Europe/Kirov',
    'Europe/Astrakhan',
    'Europe/Volgograd',
    'Europe/Saratov',
    'Europe/Ulyanovsk',
    'Europe/Samara',
    'Asia/Yekaterinburg',
    'Asia/Omsk',
    'Asia/Novosibirsk',
    'Asia/Barnaul',
    'Asia/Tomsk',
    'Asia/Novokuznetsk',
    'Asia/Krasnoyarsk',
    'Asia/Irkutsk',
    'Asia/Chita',
    'Asia/Yakutsk',
    'Asia/Khandyga',
    'Asia/Vladivostok',
    'Asia/Ust-Nera',
    'Asia/Magadan',
    'Asia/Sakhalin',
    'Asia/Srednekolymsk',
    'Asia/Kamchatka',
    'Asia/Anadyr',
    'Asia/Riyadh',
    'Pacific/Guadalcanal',
    'Indian/Mahe',
    'Africa/Khartoum',
    'Europe/Stockholm',
    'Asia/Singapore',
    'America/Paramaribo',
    'Africa/Juba',
    'Africa/Sao_Tome',
    'America/El_Salvador',
    'Asia/Damascus',
    'America/Grand_Turk',
    'Africa/Ndjamena',
    'Indian/Kerguelen',
    'Asia/Bangkok',
    'Asia/Dushanbe',
    'Pacific/Fakaofo',
    'Asia/Dili',
    'Asia/Ashgabat',
    'Africa/Tunis',
    'Pacific/Tongatapu',
    'Europe/Istanbul',
    'America/Port_of_Spain',
    'Pacific/Funafuti',
    'Asia/Taipei',
    'Europe/Kiev',
    'Europe/Uzhgorod',
    'Europe/Zaporozhye',
    'Pacific/Wake',
    'America/New_York',
    'America/Detroit',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Indiana/Indianapolis',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Vevay',
    'America/Chicago',
    'America/Indiana/Tell_City',
    'America/Indiana/Knox',
    'America/Menominee',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/North_Dakota/Beulah',
    'America/Denver',
    'America/Boise',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Anchorage',
    'America/Juneau',
    'America/Sitka',
    'America/Metlakatla',
    'America/Yakutat',
    'America/Nome',
    'America/Adak',
    'Pacific/Honolulu',
    'America/Montevideo',
    'Asia/Samarkand',
    'Asia/Tashkent',
    'America/Caracas',
    'Asia/Ho_Chi_Minh',
    'Pacific/Efate',
    'Pacific/Wallis',
    'Pacific/Apia',
    'Africa/Johannesburg'
];
import React, { useState, useEffect, useRef } from 'react';
import { ActivityIndicator, View, ScrollView, StyleSheet, Text, Image, TouchableOpacity } from 'react-native';
import { useScrollToTop } from '@react-navigation/native';
import axios from 'axios';

import Icon from 'react-native-vector-icons/MaterialIcons';

import Header from '../../Headers/LoggedInHeader';

export default function Dashboard({ navigation, host, userId, setUserId, userInfo, setUserInfo, setAppInfo, appUpdated, setAppUpdated }) {
    const [showLoading, setShowLoading] = useState(true);
    const [apps, setApps] = useState([]);
    const [showProScreen, setShowProScreen] = useState(false);
    const [email, setEmail] = useState("true");

    const ref = useRef(null);
    useScrollToTop(ref);

    function getCookie(name) {
        function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }

    useEffect(() => {
        if(getCookie('logged_in') == 'true') {
            setUserId(getCookie('userId'));
            axios
                .get(`${host}/api/all/apps/${getCookie('userId')}`)
                .then(res => {
                    setApps(res.data);
                    // console.log("res.data: ", res.data)

                    axios
                        .get(`${host}/api/user/${getCookie('userId')}`)
                        .then(response => {
                            const data = response.data;
                            setUserInfo(response.data);
                            // console.log(data);

                            setEmail(response.data.email);

                            if(!data.paid_sub) {
                                // check session to see if payment was successful
                                checkSession(data)
                            } else if(data.paid_sub && !data.custom_pricing) {
                                axios
                                    .put(`${host}/api/stripe/subscription/quantity`, { userId: data.user_id, pushSubCount: data.push_sub_count })
                                    // .then(responseToPost => console.log(responseToPost))
                                    // .catch(errorOfPost => console.log(errorOfPost))
                                setShowLoading(false);
                            }
                        })
                        .catch(err => console.log(err))
                })
                .catch(err => {
                    console.log(err);
                    setShowLoading(false);
                })
        } else {
            return navigation.navigate('Home');
        }
    }, [appUpdated]);

    const createApp = () => {
        return navigation.navigate('ChooseAFramework');
    }

    // website to app & expo
    const openWebsiteToApp = (appInfo) => {
        setAppInfo(appInfo);
        document.cookie = `app_id = ${appInfo.app_id}`;
        setAppUpdated(appInfo.app_id);
        if(getCookie('app_id') == appInfo.app_id) {
            navigation.navigate('InAppWebsiteToApp');
        }
    }
    const openExpoApp = (appInfo) => {
        setAppInfo(appInfo);
        document.cookie = `app_id = ${appInfo.app_id}`;
        setAppUpdated(appInfo.app_id);
        if(getCookie('app_id') == appInfo.app_id) {
            navigation.navigate('InApp');
        }
    }
    const deleteApp = (app_id, app_token) => {
        let question = confirm("Are you sure you want to delete your app? This cannot be undone!");
        if(question) {
            axios   
                .delete(`${host}/api/app/${app_id}/${app_token}/${userId}`)
                .then(res => {
                    setApps(res.data)
                })
                .catch(err => console.log(err))
        } 
    }

    // flutter
    const openFlutterApp = (appInfo) => {
        setAppInfo(appInfo);
        document.cookie = `flutter_app_id = ${appInfo.flutter_app_id}`;
        setAppUpdated(appInfo.flutter_app_id);
        if(getCookie('flutter_app_id') == appInfo.flutter_app_id) {
            navigation.navigate('InAppFlutter');
        }
    }
    const deleteFlutterApp = (flutter_app_id, flutter_app_token) => {
        let question = confirm("Are you sure you want to delete your app? This cannot be undone!");
        if(question) {
            axios   
                .delete(`${host}/api/flutter/app/${flutter_app_id}/${flutter_app_token}/${userId}`)
                .then(res => {
                    setApps(res.data);
                })
                .catch(err => console.log(err))
        } 
    }

    const handleStripeSubCreation = () => {
        setShowLoading(true);

        axios
            .post(`${host}/api/stripe/subscribe`, { userId, pushSubCount: userInfo.push_sub_count })
            .then((res) => {
                // console.log("res.data: ", res.data, res.data.url);
                if(res.data.url) {
                    window.location = res.data.url;
                } else {
                    setShowProScreen(false);
                    setShowLoading(false);
                }
            })
            .catch(err => {
                console.log(err);
                alert("Our server is experiencing high traffic or is in maintenance. Please try again later.");
                setShowLoading(false);
            })  
    }

    const checkSession = (data) => {
        axios
            .post(`${host}/api/stripe/check/session`, { 
                userId: getCookie('userId'), 
                pushSubCount: userInfo.push_sub_count, 
                appCount: userInfo.number_of_apps_in_account 
            })
            .then(res => {
                console.log(res.data);
                if(res.data === "fail") {
                    let today = new Date();
                    let priorDate = new Date().setDate(today.getDate()-7);
                    
                    if(+new Date(priorDate) > +new Date(data.date_signed_up) && data.push_sub_count > 10 && !data.custom_pricing) {
                        setShowProScreen(true);
                        setShowLoading(false);
                    } 
                    setShowLoading(false);
                } else {
                    setShowProScreen(false);
                    setShowLoading(false);
                }
            })
            .catch(err => {
                console.log(err);
                setShowLoading(false);
            }) 
    }

    if(showLoading) {
        return (
            <View style={styles.page}>
                <View style={styles.centerScreen}>
                    <ActivityIndicator size="large" color="rgb(54, 117, 212)" />
                </View>
            </View>
        )
    }

    return (
        <View style={styles.page}>
            <Header navigation={navigation} setUserId={setUserId} setAppInfo={setAppInfo}/>
            {/* update the showProScreen to be based on sub count */}
            {showProScreen
                ? <ScrollView 
                    ref={ref} 
                    style={styles.scrollViewCont}
                    contentContainerStyle={styles.scrollView}
                  >
                    <View>
                        <View style={styles.goProCont}>
                            <Image
                                style={styles.logo}
                                source={require('../../assets/images/logo.png')}
                                resizeMode='cover'
                            />
                            <Text style={styles.h1}>Your free trial has ended.</Text>
                            <Text style={styles.subHeader}>Your 7-day free trial has ended and you have more than 10 push notification subscribers. You must Go Premium to continue using Native Notify.</Text>
                            <button 
                                onClick={() => handleStripeSubCreation()}
                                style={styles.goPremiumButton}
                            >Go Premium</button>
                            <Text style={styles.pSmall}>Cancel anytime</Text>
                        </View>

                        <Text style={styles.mb}></Text>
                            
                        <Text style={styles.h1}>Frequently Asked Questions</Text>

                        <Text style={styles.pBold}>How does Native Notify Premium pricing work?</Text>
                        <Text style={styles.p}>Native Notify costs $25/month for your first 1,000 push notification subscribers.</Text>
                        <Text style={styles.p}>After your first 1,000 push notification subscribers, your monthly subscription fee increases $5/month for each additional 1,000 push notification subscribers.</Text>

                        <Text style={styles.pBold}>What's my current cost?</Text>
                        <Text style={styles.p}>You have {userInfo.push_sub_count} push notification subscribers, so your current monthly subscription fee is $
                                {userInfo.push_sub_count / 1000 < 1 
                                    ? '25' 
                                    : `${(Math.floor(userInfo.push_sub_count / 1000) * 5) + 25}`
                                }/month.
                        </Text>
                        
                        <Text style={styles.pBold}>What's included? </Text>
                        <Text style={styles.p}>Send unlimited push notifications. Send unlimited Indie push notifications. Unlimited Notification Inbox activity. Send unlimited Follow Push Notifications.</Text>

                        <Text style={styles.pBold}>Can I cancel anytime?</Text>
                        <Text style={styles.p}>Yes. There are no contracts. You can cancel anytime.</Text>

                        <Text style={styles.pBold}>Do you accept foreign currency?</Text>
                        <Text style={styles.p}>Yes. You can pay using a credit/debit card from your home country. We will convert your currency into USD for you.</Text>

                        <Text style={styles.pBold}>What is considered a 'push notification subscriber'?</Text>
                        <Text style={styles.p}>The following is considered a push notification subscriber:</Text>
                        <Text style={styles.p}>   - a unique device that has successfully received one of your push notifications.</Text>
                        <Text style={styles.p}>   - an Indie Push Notification subscriber</Text>
                        <Text style={styles.p}>   - a Follow Push Notification subscriber</Text>
                        <Text style={styles.p}>   - a Topic Group subscriber</Text>
                    </View>
                  </ScrollView>
                : <ScrollView 
                    ref={ref} 
                    style={styles.scrollViewCont}
                    contentContainerStyle={styles.scrollView}
                  >
                    <TouchableOpacity style={styles.createNotificationButton} onPress={() => createApp()}>
                        <Icon
                            name="add"
                            size={20}
                            color={'#fff'}
                            style={styles.icon}
                            onPress={() => createApp()}
                        />
                        <Text style={styles.createNotificationText}>Create an App</Text>
                    </TouchableOpacity>

                    <View className style={styles.flexRow}>
                        {apps
                            ? apps.map((e, i) => {
                                if(e.framework === 'expo' && !e.is_a_website) {
                                    return (
                                        <TouchableOpacity key={i} style={styles.appCont} onPress={() => openExpoApp(e)}>
                                            <Icon
                                                name="delete-forever"
                                                size={25}
                                                color={'#26272b'}
                                                style={styles.deleteButton}
                                                onPress={() => deleteApp(e.app_id, e.app_token)}
                                            />
                                            <Text style={styles.appTitle}>{e.app_name}</Text>
                                        </TouchableOpacity>
                                    )
                                } else if(e.framework === 'expo' && e.is_a_website) {
                                    return (
                                        <TouchableOpacity key={i} style={styles.appCont} onPress={() => openWebsiteToApp(e)}>
                                            <Icon
                                                name="delete-forever"
                                                size={25}
                                                color={'#26272b'}
                                                style={styles.deleteButton}
                                                onPress={() => deleteApp(e.app_id, e.app_token)}
                                            />
                                            <Text style={styles.appTitle}>{e.app_name}</Text>
                                        </TouchableOpacity>
                                    )
                                }
                                
                                if(e.framework === 'flutter') {
                                    return (
                                        <TouchableOpacity key={i} style={styles.appCont} onPress={() => openFlutterApp(e)}>
                                            <Icon
                                                name="delete-forever"
                                                size={25}
                                                color={'#26272b'}
                                                style={styles.deleteButton}
                                                onPress={() => deleteFlutterApp(e.flutter_app_id, e.flutter_app_token)}
                                            />
                                            <Text style={styles.appTitle}>{e.flutter_app_name}</Text>
                                        </TouchableOpacity>
                                    )
                                }
                            })
                            : null
                        }
                    </View>                  
                  </ScrollView>
            }
            
        </View>
    )
}

let flexRowJustify, appMR, appWH;

if(screen.width < 500) {
    flexRowJustify = 'space-between';
    appMR = 0;
} else {
    flexRowJustify = 'flex-start';
    appMR = 30;
}

if(screen.width > 500) {
    appWH = '20vw';
} else { appWH = '41vw' }

const styles = StyleSheet.create({
    page: {
        flex: 1, 
        height: '100%',
        minHeight: '100vh',
        width: '100%',
        minWidth: '100vw',
        backgroundColor: "rgb(250, 250, 250)",
    },
    scrollViewCont: {
        height: '100%',
        width: '100%'
    },
    scrollView: {
        height: '100%',
        width: '100%',
        padding: '5%',
        paddingTop: 30,
    },
    flexRow: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: flexRowJustify,
        margin: -15,
        marginTop: 0
    },
    centerScreen: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },


    goProCont: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: 15,
        paddingTop: 30,
        paddingBottom: 30,
        textAlign: 'center',
        backgroundColor: 'white',
        boxShadow: '0px 1px 3px #6b6b6b',
        borderRadius: '6px',
    },

    h1: {
        fontFamily: 'Arial',
        fontWeight: 700,
        fontSize: 24,
        marginBottom: 10,
        // textAlign: 'center'
    },
    subHeader: {
        fontFamily: 'Arial',
        fontWeight: 500,
        color: "#6b6b6b",
        fontSize: 18,
        marginBottom: 10,
        maxWidth: 800
    },
    p: {
        fontSize: 18,
        fontFamily: 'Arial',
        fontWeight: 400,
        marginBottom: 5,
    },
    pSmall: {
        fontSize: 12,
        fontFamily: 'Arial',
        fontWeight: 400,
        color: "#6b6b6b",
    },
    pBold: {
        fontSize: 18,
        fontFamily: 'Arial',
        fontWeight: 700,
        marginTop: 20
    },

    logo: {
        width: 60,
        height: 60
    },
    goPremiumButton: {
        width: 'fit-content',
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 15,
        paddingRight: 15,
        marginTop: 20,
        marginBottom: 10,
        backgroundColor: '#2690cb',
        color: 'white',
        border: '0',
        borderRadius: '6px',
        boxShadow: '0px 1px 2px #26272b',
        fontSize: '20px',
        fontWeight: '700',
        cursor: 'pointer'
    },
    createNotificationButton: {
        width: 220,
        backgroundColor: '#e54b4d',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 5,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 1px 2px #26272b',
        marginBottom: 30
    },
    createNotificationText: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 20,
        color: '#fff',
        marginLeft: 10,
        marginRight: 3
    },
    deleteButton: {
        position: 'absolute',
        top: 7.5,
        right: 10
    },
    icon: {
        paddingTop: 2,
        marginLeft: -1
    },

    appCont: {
        margin: 15,
        // marginLeft: 0,
        // marginRight: appMR,
        height: appWH,
        width: appWH,
        // minHeight: 160,
        // minWidth: 160,
        borderRadius: 6,
        boxShadow: '0 2px 5px 2px #d6d6d6',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 15,
        backgroundColor: '#fff',
    },
    appTitle: {
        fontFamily: 'Arial',
        fontWeight: 600,
        backgroundColor: '#26272b',
        color: 'white',
        padding: 15,
        borderRadius: 6,
        boxShadow: '0 2px 5px #26272b',
        textAlign: 'center'
    },
    mb: {
        marginBottom: 60
    }
});
import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image, TextInput } from 'react-native';

import Icon from 'react-native-vector-icons/MaterialIcons';

export default function HomeHeader({ navigation, setTemporaryUserId }) {
    return (
        <View style={styles.headerCont}>
            <TouchableOpacity style={styles.smallCont} onPress={() => { navigation.navigate('Home'); setTemporaryUserId('') }}>
                {/* <Icon
                    name="notifications-on"
                    size={20}
                    onPress={() => console.log('click')}
                /> */}
                <Image
                    style={styles.logo}
                    source={require('../assets/images/logo.png')}
                    resizeMode='cover'
                />
                <Text style={styles.logoText}>Native Notify</Text>
            </TouchableOpacity>
           
            <View style={styles.smallCont}>
                <TouchableOpacity style={styles.signUpButton} onPress={() => navigation.navigate('Login')}>
                    <Text style={styles.whiteText}>Login</Text>
                </TouchableOpacity>
                {/* <TouchableOpacity style={styles.signUpButton} onPress={() => navigation.navigate('SignUpOne')}>
                    <Text style={styles.whiteText}>Sign Up</Text>
                </TouchableOpacity> */}
            </View> 
        </View>
    )
}

const styles = StyleSheet.create({
    headerCont: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 20,
        paddingLeft: '5%',
        paddingRight: '5%',
        flexWrap: 'wrap'
    },
    smallCont: {
        height: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    logo: {
        width: 30,
        height: 30
    },
    logoText: {
        fontSize: 20,
        fontFamily: 'Arial',
        fontWeight: 600,
        marginLeft: 15
    },
    loginButton: {
        cursor: 'pointer',
        padding: 5,
        paddingLeft: 10,
        paddingRight: 10,
        marginRight: 10,
    },
    signUpButton: {
        cursor: 'pointer',
        padding: 5,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: '#000',
        borderRadius: 3
    },
    blackText: {
        color: '#000',
        fontFamily: 'Arial',
        fontWeight: 400,
    },
    whiteText: {
        color:'#fff',
        fontFamily: 'Arial',
        fontWeight: 400,
    }
});
import React, { useState, useEffect } from 'react';
import { ActivityIndicator, View, Text, TouchableOpacity, TextInput, StyleSheet } from 'react-native';
import axios from 'axios';

import Header from '../../../Headers/LoginSignUpResetHeader';

export default function ResetPasswordOne({ host, navigation, setUserId, setCategory }) {
    const [email, setEmail] = useState('');
    const [temporaryUserId, setTemporaryUserId] = useState('');
    const [resetCode, setResetCode] = useState('');
    const [confirmResetCode, setConfirmResetCode] = useState('');
    const [showThirdScreen, setShowThirdScreen] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    

    const sendResetCode = () => {
        setShowLoading(true);
        axios
            .post(`${host}/api/resetCode`, { email })
            .then(res => {
                console.log("res.data: ", res.data);
                if(res.data === "Sorry, that email is not associated with a Native Notify account. Please try again.") {
                    setShowLoading(false);
                    return alert("Sorry, that email is not associated with a Native Notify account. Please try again.")
                }
                setShowLoading(false);
                setResetCode(res.data.code);
                setTemporaryUserId(res.data.user_id);
            })
            .catch(err => console.log(err))
    }

    const checkResetCode = () => {
        if(resetCode == confirmResetCode) {
            setShowThirdScreen(true);
        } else {
            alert("Sorry, the reset code you typed does not match our records. Please, try again.")
        }
    }

    const checkPassword = () => {
        if(password == confirmPassword) {
            setUserId(temporaryUserId);
            axios
                .post(`${host}/api/resetPassword`, { email, password })
                .then(res => {
                    console.log(res.data);
                    alert("Your password was successfully reset.")
                    navigation.navigate('LoginOrSignUp');
                })
        } else {
            alert("Sorry, the password and confirm password do not match. Please, try again.")
        }
    }

    return (
        <View style={styles.page}>
            <Header navigation={navigation} setTemporaryUserId={setTemporaryUserId} />

            {showLoading
                ? <ActivityIndicator size="large" color="rgb(54, 117, 212)" />
                : null
            }

            {!temporaryUserId && !showThirdScreen
                ? <View style={styles.body}>
                    <Text style={styles.headerText}>Reset Password</Text>
                    <Text style={styles.subtitleText}>What email is associated with your account?</Text>
                    <TextInput 
                        style={styles.inputLarge}
                        onChangeText={(text) => setEmail(text.toLowerCase())}
                        placeholder={"Email"}
                        placeholderTextColor={"#6e6d6c"}
                        value={email}
                    />
                    <TouchableOpacity style={styles.button} onPress={() => sendResetCode()}>
                        <Text style={styles.text}>Submit</Text>
                    </TouchableOpacity>
                  </View>
                : null
            }

            {temporaryUserId && !showThirdScreen
                ? <View style={styles.body}>
                    <Text style={styles.headerText}>Check your email</Text>
                    <Text style={styles.subtitleText}>We sent a password reset code to your email. Please type that code below:</Text>
                    <TextInput 
                        style={styles.inputLarge}
                        onChangeText={(text) => setConfirmResetCode(text)}
                        placeholder={"Reset code"}
                        placeholderTextColor={"#6e6d6c"}
                        value={confirmResetCode}
                    />
                    <TouchableOpacity style={styles.button} onPress={() => checkResetCode()}>
                        <Text style={styles.text}>Submit</Text>
                    </TouchableOpacity>
                  </View>
                : null
            }

            {showThirdScreen
                ? <View style={styles.body}>
                    <Text style={styles.headerText}>Reset Password</Text>
                    <TextInput 
                        style={styles.inputLarge}
                        onChangeText={(text) => setPassword(text)}
                        placeholder={"New Password"}
                        placeholderTextColor={"#6e6d6c"}
                        value={password}
                        secureTextEntry={true}
                    />
                    <TextInput 
                        style={styles.inputLarge2}
                        onChangeText={(text) => setConfirmPassword(text)}
                        placeholder={"Confirm New Password"}
                        placeholderTextColor={"#6e6d6c"}
                        value={confirmPassword}
                        secureTextEntry={true}
                    />
                    <TouchableOpacity style={styles.button} onPress={() => checkPassword()}>
                        <Text style={styles.text}>Reset Password</Text>
                    </TouchableOpacity>
                  </View>
                : null
            }
        </View>
    )
}

const styles = StyleSheet.create({
    page: {
        flex: 1, 
        minHeight: 500,
        alignItems: "center",
        backgroundColor: "#95969c01",
    },
    body: {
        width: "95%",
        maxWidth: 500,
        maxHeight: 700,
        padding: "4%",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        marginTop: 60,
    },
    button: {
        width: "100%",
        height: 45,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#000",
        borderRadius: 6,
        marginBottom: 20,
        fontSize: 20,
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 15,
    },
    headerText: {
        width: "100%",
        textAlign: "left",
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 30,
    },
    subtitleText: {
        width: "100%",
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        color: "#000",
        marginBottom: 20,
    },
    
    inputLarge: {
        width: "100%",
        padding: 12.5,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginTop: 30,
    },
    inputLarge2: {
        width: "100%",
        padding: 12.5,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginTop: 10,
        marginBottom: 30,
    },
    signUpCont: {
        width: "100%",
        alignItems: "center",
        marginTop: 25
    },
    text: {
        fontFamily: "Arial",
        fontWeight: 700,
        color: "white",
        fontSize: 20
    }
});
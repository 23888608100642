import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Button, TextInput } from 'react-native';
import axios from 'axios';
import StripeCheckout from 'react-stripe-checkout';

export default function StripeButton({ onToken }){
    const [showMobile, setShowMobile] = useState(false);

    useEffect(() => {
        if(screen.width < 500) {
            setShowMobile(true)
        } else {
            setShowMobile(false)
        }
    })

    return (
        <TouchableOpacity 
            style={styles.signUpButton} 
            // onPress={() => navigation.navigate('SignUpOne')}
        >
            {showMobile
                ? <StripeCheckout
                    // label='Start Your Free Trial Now'
                    
                    image={require('../../../../assets/images/logo.png')}
                    name="Try it free for 7 days."
                    description="$29.99/m. Cancel anytime."
                    panelLabel="Start Your Free Trial"
                    
                    currency='USD'
                    locale='auto'
                    stripeKey='pk_live_9o7xarrQQSR8yfoIwh5r2uIk00yFNr81db'
                    token={onToken}
                  >
                    <Text style={styles.whiteText}>Create a New Account</Text>
                  </StripeCheckout>
                : <StripeCheckout
                    // label='Start Your Free Trial Now'
                    
                    image={require('../../../../assets/images/logo.png')}
                    name="Try it free for 7 days."
                    description="Then $29.99/month. Cancel anytime."
                    panelLabel="Start Your Free Trial"
                    
                    currency='USD'
                    locale='auto'
                    stripeKey='pk_live_9o7xarrQQSR8yfoIwh5r2uIk00yFNr81db'
                    token={onToken}
                  >
                    <Text style={styles.whiteText}>Create a New Account</Text>
                  </StripeCheckout>
            }
           
        </TouchableOpacity>
        
    )
}

const styles = StyleSheet.create({
    signUpButton: {
        marginTop: 40,
        marginBottom: 30,
    },
    whiteText: {
        height: '100%',
        width: 250,
        fontSize: 18,
        color:'#fff',
        fontFamily: 'Arial',
        fontWeight: 600,
        padding: 10,
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: '#45b1e8',
        backgroundImage: 'linear-gradient(#45b1e8,#3097de)',
        boxShadow: '0 1px 0 rgb(46 86 153 / 15%), 0 0 4px rgb(86 149 219 / 0%), inset 0 2px 0 rgb(41 102 20 / 0%)',
        borderRadius: 4,
    }
})
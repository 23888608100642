import React, { useState, useEffect, useRef } from 'react';
import { View, TextInput, StyleSheet, Text, TouchableOpacity } from 'react-native';
import axios from 'axios';

export default function NotificationInbox({ host, appInfo, InAppNavigation }) {
    const { setScreenName } = InAppNavigation;

    const [notificationInbox, setNotificationInbox] = useState([]);

    useEffect(() => {
        axios
            .get(`${host}/api/notification/inbox/${appInfo.app_id}/${appInfo.app_token}`)
            .then(res => {
                setNotificationInbox(res.data);
            })
            .catch(err => console.log(err))
    }, [])

    const handleDelete = (notificationId) => {
        axios
            .delete(`${host}/api/notification/inbox/notification/${appInfo.app_id}/${appInfo.app_token}/${notificationId}`)
            .then(res => {
                setNotificationInbox(res.data);
            })
            .catch(err => console.log(err))
    }

    return (
        <View style={styles.body}>
            <Text style={styles.HeadingText}>Your Notification Inbox Notifications:</Text>
            <Text style={styles.plainText}>These notifications will appear in your Notification Inbox. You can delete any notifications you want before going live with your Notification Inbox.</Text>
            {notificationInbox.map((e, i) => {
                return (
                    <View style={styles.recCont} key={i}>
                        <View style={styles.leftPart}>
                            <Text style={styles.recText}><Text style={styles.recTextBold}>Date:</Text>     {e.date}</Text>
                            <Text style={styles.recText}><Text style={styles.recTextBold}>Title:</Text>     {e.title}</Text>
                            <Text style={styles.recText}><Text style={styles.recTextBold}>Message:</Text>     {e.message}</Text>
                            <Text style={styles.recText}><Text style={styles.recTextBold}>Push Data Object:</Text>     {e.pushData}</Text>
                        </View>
                        
                        <TouchableOpacity style={styles.button} onPress={() => handleDelete(e.notification_id)}><Text style={styles.buttonText}>DELETE THIS NOTIFICATION</Text></TouchableOpacity>
                    </View>
                )
            })}
        </View>
    )
}

const styles = StyleSheet.create({
    body: {
        width: '85%',
        paddingTop: 30,
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingBottom: 200
    },
    HeadingText: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        // marginTop: 70,
        marginBottom: 10
    },
    plainText: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 5
    },
    recCont: {
        width: '100%',
        backgroundColor: '#fff',
        marginTop: 10,
        marginBottom: 10,
        padding: 20,
        paddingBottom: 15,
        borderRadius: 6,
        flexWrap: 'wrap',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    recText: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 14,
        marginBottom: 5,
    },
    recTextBold: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 5,
    },
    leftPart: {
        width: '50%'
    },
    button: {
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        padding:20,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
    },
    buttonText: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 14,
        color: '#fff',
    },
    mb: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 10
    },
    blueText: {
        color: "rgb(54, 117, 212)"
    },
    boldText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginTop: 20,
        marginBottom: 60
    }
})
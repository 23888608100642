import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, TextInput, StyleSheet, ScrollView } from 'react-native';
import axios from 'axios';

import Header from '../../../Headers/HomeHeader';
import StripeButton from './Components/StripeButton';

export default function LoginOrSignUp({ navigation, host, setUserId }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [showLogin, setShowLogin] = useState(false);

    function getCookie(name) {
        function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }

    useEffect(() => {
        if(getCookie('logged_in') == 'true') {
            setUserId(getCookie('userId'));
            navigation.navigate('Dashboard');
        } else {
            setShowLogin(true)
        }
    }, []);

    const handlePost = () => {
        axios
            .post(`${host}/api/auth/login`, { email, password })
            .then(res => {
                document.cookie = `userId = ${res.data.user_id}; max-age=31536000;`;
                document.cookie = `userId = ${res.data.user_id}; domain=nativenotify.com; max-age=31536000;`;
                document.cookie = `userId = ${res.data.user_id}; domain=app.nativenotify.com; max-age=31536000;`;
                document.cookie = `logged_in = true; max-age=31536000;`;
                document.cookie = `logged_in = true; domain=nativenotify.com; max-age=31536000;`;
                document.cookie = `logged_in = true; domain=app.nativenotify.com; max-age=31536000;`;
                setUserId(res.data.user_id);
                navigation.navigate('Dashboard');
            })
            .catch(err => {
                console.log(err);
                alert("Sorry, that email and password did not match out records. Please try again.")
            })  
    }

    if(showLogin) 
    return (
        <View style={styles.page}>
            <Header navigation={navigation}/>
                <View style={styles.body}>
                    <ScrollView keyboardShouldPersistTaps={'handled'}>
                        <TextInput 
                            style={styles.inputLarge}
                            onChangeText={(text) => setEmail(text.toLowerCase())}
                            placeholder={"Email"}
                            placeholderTextColor={"#6e6d6c"}
                            value={email}
                        />
                        <TextInput 
                            style={styles.inputLarge}
                            onChangeText={(text) => setPassword(text)}
                            placeholder={"Password"}
                            placeholderTextColor={"#6e6d6c"}
                            value={password}
                            secureTextEntry={true}
                        />
                        <View style={styles.loginCont}>
                            <TouchableOpacity style={styles.buttons} onPress={() => handlePost()}>
                                <Text style={styles.text}>Login</Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => navigation.navigate('ResetPasswordOne')}>
                                <Text style={styles.textForgot}>Forgot Password?</Text>
                            </TouchableOpacity> 
                        </View>

                        {/* <View style={styles.signUpButtonCont}>
                            <StripeButton onToken={onToken} />
                        </View> */}
                        

                        <TouchableOpacity style={styles.signUpButton} onPress={() => navigation.navigate('SignUpOne')}>
                            <Text style={styles.text}>Create New Account</Text>
                        </TouchableOpacity>
                    </ScrollView>  
                </View>
        </View>
    )
}

const styles = StyleSheet.create({
    page: {
        flex: 1, 
        minHeight: 500,
        alignItems: "center",
        backgroundColor: "#3576d4",
    },
    body: {
        width: "95%",
        maxWidth: 500,
        maxHeight: 700,
        padding: "4%",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        marginTop: 60,
    },
    buttons: {
        height: 45,
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#000",
        borderRadius: 6,
        marginBottom: 20,
    },
    inputLarge: {
        width: "100%",
        padding: 12.5,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 15
    },
    loginCont: { 
        width: "100%", 
        alignItems: "center",
        borderBottomWidth: 1, 
        borderBottomColor: "#99999960",
        paddingBottom: 30
    },
    signUpButton: {
        height: 45,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#06bd43",
        borderRadius: 6,
        marginBottom: 20,
        fontFamily: "Arial",
        fontWeight: 700,
        color: "white",
        fontSize: 20,
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 30,
    },
    signUpButtonCont: {
        justifyContent: "center",
        alignItems: "center",
    },
    text: {
        fontFamily: "Arial",
        fontWeight: 700,
        color: "white",
        fontSize: 20
    },
    textForgot: {
        fontFamily: "Arial",
        fontWeight: 600,
        // color: "#95969c",
        fontSize: 18
    }
});